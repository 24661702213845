import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../../.../../../Auth/components/axiosInterceptors';
import { setAlertFunc } from '../../../../components/AlertToaster/AlertSlice';
import urls from '../../../../enums/urlList';

const initialState = {
    category_id: "",
    report_id: "",
    loading: false,
    addFormatLoading: false,
    addSuccess: false,
    format: []
}


export const getFormat: any = createAsyncThunk(
    'format', async (obj:any, thunkApi: any) => {
        try {
            const response: any = await axiosInstance(`settings/upload_report_formats?module_id=${obj?.module_id}&modules_flag=${obj?.modules_flag}`, {
                method: 'GET',
                data: JSON.stringify(thunkApi)
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });

export const addFormat: any = createAsyncThunk(
    'addFormat',
    async (thunkAPI: any, { getState, dispatch }: any) => {

        try {
            const response: any = await axiosInstance(`${urls.base_url}settings/upload_report_formats`, {
                method: 'POST',
                data: thunkAPI.fileData,
            })
            response.status === 200 && dispatch(getFormat(thunkAPI));
            dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            return response.data
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const editUploadFormat: any = createAsyncThunk(
    'editUploadFormat', async (thunkApi: any, { dispatch }) => {
        try {
            const response: any = await axiosInstance(`settings/upload_report_formats`, {
                method: 'PUT',
                // data: JSON.stringify(thunkApi)
                data: JSON.stringify({
                    category_id: thunkApi?.category_id,
                    user_id: thunkApi?.user_id,
                    id: thunkApi?.id,
                })
            })
            dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            dispatch(getFormat({module_id:thunkApi?.module_id,modules_flag:thunkApi?.modules_flag},thunkApi))
            return response.data
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        }
    })

const formatSlice = createSlice({
    name: 'formats',
    initialState,
    reducers: {},
    extraReducers: (builder: any) => {
        return builder.addCase(getFormat.pending, (state: any) => {
            state.loading = true
            state.format = []
            state.addSuccess = false
        }),
            builder.addCase(getFormat.fulfilled, (state: any, { payload }: any) => {
                state.loading = false
                state.format = payload
                state.addSuccess = false
            }),
            builder.addCase(getFormat.rejected, (state: any) => {
                state.loading = false
                state.format = []
            }),
            builder.addCase(addFormat.pending, (state: any) => {
                state.addFormatLoading = true
                state.addSuccess = false
            }),
            builder.addCase(addFormat.fulfilled, (state: any) => {
                state.addFormatLoading = false
                state.addSuccess = true
            }),
            builder.addCase(addFormat.rejected, (state: any) => {
                state.addFormatLoading = false
            })
    },
})

export const getAllFormats = (state: any) => state.format;
export const addFormatSuccess = (state: any) => state.format.addSuccess;
export const getAddFormatLoading = (state: any) => state.format.addFormatLoading;
export const editFormatSuccess = (state: any) => state.format.addSuccess;

export default formatSlice.reducer;