import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance } from '../../../Auth/components/axiosInterceptors'
import urls from "../../../../enums/urlList";

import { setAlertFunc } from '../../../../components/AlertToaster/AlertSlice'
import axios from 'axios';
import { submitFinalUploads } from '../../../../utils/utils';
const initialState = {
    users: [],
    loading: false,
    addSuccess: false,
    totalPages: 1,
    page: 1,
    selectedValue: 10,
    addLoading: false,
    editLoading: false,
    EditSuccess: false,
    chargerDisplayValues: [],
    chargerCurrentRules: [],
    StateDisplayValues: [],
    StateCurrentRules: [],
    CityDisplayValues: [],
    CityCurrentRules: [],
    currentRules: [],
    displayValues: [],
    SurveyFormValue: {
        cityId: [],
        stateId: [],
        chargerIds: [],
        statusId: [],
        age_op: '',
        age: [],
        more_than_age: '',
        between_age1: '',
        between_age2: '',
        less_than_age: '',
        assigned_to: '',
        TicketStartDate: '',
        TicketEndDate: '',
        ETDStartDate: '',
        ETDEndDate: '',
        ClosureStartDate: '',
        ClosureEndDate: '',
        TaxationStartDate: '',
        TaxationEndDate: '',
        search_key: '',
        sow: '',
        ReadUnread: 'all',
        ticketType: 'all',
        vendor_ids: [],
        parent_status: 'all_tab',
        no_of_spots: ''
    },
    SurveyTabsLoading: false,
    SurveyTabsData: [],
    SurveyLoading: false,
    SurveyData: [],
    SurveyDetailLoading: false,
    SurveyDealDetailData: [],
    SurveyHistoryLoading: false,
    SurveyHistoryData: [],
    SurveyOthersLoading: false,
    SurveyOthersData: [],
    SurveyButtonLoading: false,
    SurveyButtonData: [],
    deleteTransactionLoading: false,
    deleteTransactionSuccess: false,
    deletePaymentSuccess: false,
    deletePaymentLoading: false,
    addPurchaseOrderSuccess: false,
    addPurchaseOrderLoading: false,
    editTicketDetailsSuccess: false,
    editTicketDetailsLoading: false,
    editTicketStatusSuccess: false,
    editTicketStatusLoading: false,
    skipStatusLoading: false,
    skipStatusData: [],
    editSkipStatusSuccess: false,
    editSkipStatusLoading: false,
    ChargerTemplateLoading: false,
    addTransactionSuccess: false,
    addTransactionLoading: false,
    verifyTransactionSuccess: false,
    verifyTransactionLoading: false,
    addPaymentTermSuccess: false,
    addPaymentTermLoading: false,
    ChargerTemplateData: [],
    PaymentConditionLoading: false,
    PaymentConditionData: [],
    PaymentTimeLoading: false,
    PaymentTimeData: [],
    SurveyDealTab: 0,
    selectedTab: 0,
    vendorDisplayValues: [],
    vendorCurrentRules: [],
    is_add_survey_ticket_loading: false,
    is_add_survey_ticket_success: false,
    DropTicket:[],
    SurveyDetailsData: [],
    SurveyDetailsLoading: false,
    editSurveyDetailsLoading: false,
    editSurveyDetailSuccess: false,
    SurveyButtonDropdownLoading: false,
    SurveyButtonDropdownData: [],
    SurveyButtonDropdownSuccess: false,
}

export const getSurveyTabs: any = createAsyncThunk(
    'getSurveyTabs', async (obj: any, thunkApi) => {
        try {
            const response: any = await axiosInstance(`operations/survey/total`, {
                method: 'GET',
                params: {
                    'state_id': JSON.stringify(obj.data.stateId ?? []),
                    'status_ids': JSON.stringify(obj.data.statusId ?? []),
                    "city_id": JSON.stringify(obj.data.cityId ?? []),
                    'assigned_to': obj.data.assigned_to?.id ?? '',
                    'age': JSON.stringify(obj.data.age ?? []),
                    'age_op': obj.data.age_op ?? '',
                    'charger_type': JSON.stringify(obj.data.chargerIds ?? []),
                    'search_key': obj.data.search_key ?? '',
                    'read_filter': obj.data.ReadUnread ?? 'all',
                    'per_page': obj?.selectedValue,
                    'page': obj?.page,
                    'vendor_ids': JSON.stringify(obj.data.vendor_ids ?? []),
                    'type': obj.data.ticketType ?? 'all',
                    'no_of_spots': obj.data.no_of_spots ?? ''
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const getSurveyData: any = createAsyncThunk(
    'getSurveyData', async (obj: any, { thunkApi, dispatch }: any) => {
        try {
            const response: any = await axiosInstance(`operations/survey/${obj.data.parent_status ?? 'all_tab'}`, {
                method: 'GET',
                params: {
                    'state_id': JSON.stringify(obj.data.stateId ?? []),
                    'status_ids': JSON.stringify(obj.data.statusId ?? []),
                    "city_id": JSON.stringify(obj.data.cityId ?? []),
                    'assigned_to': obj.data.assigned_to?.id ?? '',
                    'age': JSON.stringify(obj.data.age ?? []),
                    'age_op': obj.data.age_op ?? '',
                    'charger_type': JSON.stringify(obj.data.chargerIds ?? []),
                    'search_key': obj.data.search_key ?? '',
                    'read_filter': obj.data.ReadUnread ?? 'all',
                    'per_page': obj?.selectedValue,
                    'page': obj?.page,
                    'vendor_ids': JSON.stringify(obj.data.vendor_ids ?? []),
                    'type': obj.data.ticketType ?? 'all',
                    'no_of_spots': obj.data.no_of_spots ?? ''
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const getVendorList: any = createAsyncThunk(
    'getVendorList', async (thunkApi: any) => {
        try {
            const response: any = await axiosInstance(`third_party/vendor_list`, {
                method: 'GET',
                params: {
                    "search_key": thunkApi.search_key ?? '',
                    "category_id": thunkApi.category_id ?? ''
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const getTicketDetails: any = createAsyncThunk(
    'ticketDetail', async (ticket_id: any, thunkApi: any) => {
        try {
            const response: any = await axiosInstance(`operations/survey/ticket_details`, {
                method: 'GET',
                params: {
                    "ticket_id": ticket_id ?? '',
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const getTicketHistory: any = createAsyncThunk(
    'history', async (ticket_id: any, thunkApi: any) => {
        try {
            const response: any = await axiosInstance(`survey/ticket_history`, {
                method: 'GET',
                params: {
                    "ticket_id": ticket_id ?? '',
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const getOtherDetails: any = createAsyncThunk(
    'getOtherDetails', async (ticket_id: any, thunkApi: any) => {
        try {
            const response: any = await axiosInstance(`survey/other_details`, {
                method: 'GET',
                params: {
                    "ticket_id": ticket_id ?? '',
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const getSkipStatuses: any = createAsyncThunk(
    'getStatuses', async (obj: any, thunkApi: any) => {
        try {
            const response: any = await axiosInstance(`survey/statuses`, {
                method: 'GET',
                params: {
                    "search_key": obj.search_key ?? '',
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
    export const getSurveyHistory: any = createAsyncThunk(
        'getSurveyHistory', async (ticket_id: any, thunkApi: any) => {
            try {
                const response: any = await axiosInstance(`operations/survey/history`, {
                    method: 'GET',
                    params: {
                        "ticket_id": ticket_id ?? '',
                    }
                })
                return response.data;
            } catch (error: any) {
                return thunkApi.rejectWithValue({ error: error.message });
            }
        });
export const getButtonDropdown: any = createAsyncThunk(
    'getButtonDropdown', async (ticket_id: any, thunkApi: any) => {
        try {
            const response: any = await axiosInstance(`survey/button`, {
                method: 'GET',
                params: {
                    "ticket_id": ticket_id ?? '',
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });

export const getPaymentConditions: any = createAsyncThunk(
    'getPaymentConditions', async (__, thunkApi: any) => {
        try {
            const response: any = await axiosInstance(`survey/conditions`, {
                method: 'GET',
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const getPaymentTime: any = createAsyncThunk(
    'getPaymentTime', async (__, thunkApi: any) => {
        try {
            const response: any = await axiosInstance(`survey/time_uom`, {
                method: 'GET',
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });

export const addTicketDetails: any = createAsyncThunk(
    'addFormat',
    async (thunkAPI: any, { getState, dispatch }: any) => {

        try {
            const response: any = await axiosInstance(`${urls.base_url}survey/ticket_details`, {
                method: 'POST',
                data: thunkAPI.data,
            })
            response.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            response.status === 200 && dispatch(getTicketDetails(thunkAPI.data.get('ticket_id')))
            if (response.status === 200 && thunkAPI.toggle !== undefined) {
                thunkAPI.toggle();
            }
            return response.data
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const addPaymentTerm: any = createAsyncThunk(
    'addPaymentTerm',
    async (thunkAPI: any, { getState, dispatch }: any) => {
        try {
            const response: any = await axiosInstance(`${urls.base_url}survey/payment_term`, {
                method: 'POST',
                data: thunkAPI.data,
            })
            response.status === 201 && dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            response.status === 201 && dispatch(getTicketDetails(thunkAPI.data.get('ticket_id')))
            if (response.status === 201 && thunkAPI.toggle !== undefined) {
                thunkAPI.toggle();
            }
            return response.data
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const addTransaction: any = createAsyncThunk(
    'addTransaction',
    async (thunkAPI: any, { getState, dispatch }: any) => {
        try {
            const response: any = await axiosInstance(`${urls.base_url}survey/transaction`, {
                method: 'POST',
                data: thunkAPI.data,
            })
            response.status === 201 && dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            response.status === 201 && dispatch(getTicketDetails(thunkAPI.data.get("ticket_id")))
            if (response.status === 201 && thunkAPI.toggle !== undefined) {
                thunkAPI.toggle();
            }
            return response.data
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);
export const VerifyTransaction: any = createAsyncThunk(
    'VerifyTransaction', async (thunkApi: any, { getState, dispatch }: any) => {
        try {
            const response: any = await axiosInstance(`${urls.base_url}survey/verify_transaction`, {
                method: 'POST',
                data: thunkApi.data,
            });
            response.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            response.status === 200 && dispatch(getTicketDetails(thunkApi.data.get("ticket_id")))
            response.status === 200 && dispatch(getButtonDropdown(thunkApi.data.get("ticket_id")))
            if (response.status === 200 && thunkApi.toggle !== undefined) {
                thunkApi.toggle();
            }
            return response.data;
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const editTicketDetails: any = createAsyncThunk(
    'editTicketDetails', async (thunkApi: any, { getState, dispatch }: any) => {
        try {
            const response: any = await axiosInstance(`${urls.base_url}survey/ticket`, {
                method: 'PUT',
                data: thunkApi.data,
            });
            response.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            response.status === 200 && dispatch(getTicketDetails(thunkApi.data.get("ticket_id")))
            response.status === 200 && dispatch(getOtherDetails(thunkApi.data.get("ticket_id")))
            response.status === 200 && dispatch(getButtonDropdown(thunkApi.data.get("ticket_id")))
            if (response.status === 200 && thunkApi.toggle !== undefined) {
                thunkApi.toggle();
            }
            return response.data;
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });

export const editSkipStatus: any = createAsyncThunk(
    'editSkipStatus', async (thunkApi: any, { getState, dispatch }: any) => {
        try {
            const response: any = await axiosInstance(`${urls.base_url}survey/skip_to_status`, {
                method: 'PUT',
                data: thunkApi.data,
            });
            response.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            response.status === 200 && dispatch(getTicketDetails(thunkApi.data.get("ticket_id")))
            response.status === 200 && dispatch(getButtonDropdown(thunkApi.data.get("ticket_id")))
            return response.data;
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });

export const editTicketStatus: any = createAsyncThunk(
    'editTicketStatus', async (thunkApi: any, { getState, dispatch }: any) => {
        try {
            const response: any = await axiosInstance(`${urls.base_url}operations/survey/ticket_put`, {
                method: 'PUT',
                data: thunkApi.data,
            });
            response.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            response.status === 200 && dispatch(getSurveyDetails(thunkApi.data.get("ticket_id")))
            response.status === 200 && dispatch(getSurveyButtonDropdown({ticket_id: thunkApi.data.get("ticket_id")}))
            if (response.status === 200 && thunkApi.toggle !== undefined) {
                thunkApi.toggle();
            }
            return response.data;
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
    export const DropTicketStatus: any = createAsyncThunk(
        'DropTicketStatus', async (obj: any, { thunkApi,getState, dispatch }: any) => {
            try {
                const response: any = await axiosInstance(`${urls.base_url}operations/survey/drop_ticket`, {
                    method: 'GET',
                    params:{
                        "reason" : obj?.reason,
                        "ticket_id": obj?.ticket_id
                    },
                });
                response.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
                response.status === 200 && dispatch(getSurveyDetails(obj?.ticket_id))
                response.status === 200 && dispatch(getSurveyButtonDropdown({ticket_id: obj?.ticket_id}))
                if (response.status === 200 && obj?.toggle !== undefined) {
                    obj?.toggle();
                }
                return response.data;
            }
            catch (error: any) {
                dispatch(setAlertFunc({ type: 'error', msg: error.message }))
                return thunkApi.rejectWithValue({ error: error.message });
            }
        });
export const deleteTransaction: any = createAsyncThunk(
    'deleteTransaction', async (thunkApi: any, { getState, dispatch }: any) => {
        const response: any = await axiosInstance(`survey/transaction?ticket_id=${thunkApi?.ticket_id}&user_id=${thunkApi?.user_id}&transaction_id=${thunkApi?.transaction_id}`, {
            method: 'DELETE',
        }).then(
            (data: any) => {
                dispatch(getTicketDetails(thunkApi?.ticket_id))
                data.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: data?.data?.message }))
                return data
            }
        ).catch((error: any) => {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        })
        return response
    });
export const deletePaymentTerm: any = createAsyncThunk(
    'deletePaymentTerm', async (thunkApi: any, { getState, dispatch }: any) => {
        const response: any = await axiosInstance(`survey/payment_term?ticket_id=${thunkApi?.ticket_id}&user_id=${thunkApi?.user_id}&payment_term_id=${thunkApi?.payment_term_id}`, {
            method: 'DELETE',
        }).then(
            (data: any) => {
                dispatch(getTicketDetails(thunkApi?.ticket_id))
                data.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: data?.data?.message }))
                return data
            }
        ).catch((error: any) => {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        })
        return response
    });

export const getSurveyDetails: any = createAsyncThunk(
    'getSurveyDetails', async (ticket_id: any, thunkApi: any) => {
        try {
            const response: any = await axiosInstance(`operations/survey/ticket_details`, {
                method: 'GET',
                params: {
                    "ticket_id": ticket_id ?? '',
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const getSurveyButtonDropdown: any = createAsyncThunk(
    'getSurveyButtonDropdown', async (obj: any, thunkApi) => {
        try {
            const response: any = await axiosInstance(`operations/survey/button_dropdown`, {
                method: 'GET',
                params: {
                    "ticket_id": obj.ticket_id ?? '',
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const editSurveyDetails: any = createAsyncThunk(
    'editSurvey', async (thunkApi: any, { getState, dispatch }: any) => {
        try {
            const response: any = await axiosInstance(`${urls.base_url}operations/survey/ticket_details`, {
                method: 'PUT',
                data: thunkApi?.data,
            });
            dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            dispatch(getSurveyDetails(thunkApi.data.get('ticket_id')))
            dispatch(getSurveyButtonDropdown({ ticket_id: thunkApi.data.get('ticket_id')}))
            return response.data;
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
    export const editSurveyImages: any = createAsyncThunk(
        'editSurvey', async (thunkApi: any, { getState, dispatch }: any) => {
            try {
                const response = await axiosInstance(`${urls.base_url}edit_documents`, {
                    method: 'PUT',
                    data: thunkApi?.opsId ? {
                        ...thunkApi?.data,
                        ticket_id: thunkApi?.opsId
                    } : thunkApi?.data
                })
                if(response.data.urls?.[0]?.urls) {
                    let promises: Promise<any>[] = []
                    let responseUrl = response.data.urls?.[0]?.urls
                    responseUrl?.forEach(async (url: { presigned_url: string }, index: number) => {
                        const promise = axios.put(url.presigned_url, thunkApi?.fileData[index], {
                            headers: {
                                "Content-Type": thunkApi?.fileData[index].type
                            }
                        })
                        promises.push(promise)
                    })
                    await Promise.all(promises)
                    let finalPayload = {
                        ticket_id: thunkApi.data.ticket_id,
                        file_infos: response.data.urls?.[0]?.urls?.map((i: any) => ({
                            file_url: i.file_url[0],
                            report_id: i.report_id,
                            doc_id: i.doc_id,
                        })),
                    }
                    await submitFinalUploads(finalPayload)
                }
                dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
                dispatch(getSurveyDetails(thunkApi?.data?.ticket_id))
                dispatch(getSurveyButtonDropdown({ ticket_id: thunkApi?.data?.ticket_id}))
                return response.data;
            }
            catch (error: any) {
                dispatch(setAlertFunc({ type: 'error', msg: error.message }))
                return thunkApi.rejectWithValue({ error: error.message });
            }
        });

export const addSurveyTicket: any = createAsyncThunk(
    'addSurveyTicket', async (thunkApi: any, { getState, dispatch }: any) => {
        try {
            const response: any = await axiosInstance(`${urls.base_url}operations/survey/ticket_details`, {
                method: 'POST',
                data: thunkApi?.data,
            })
            const currentState: any = getState();
            dispatch(getSurveyData({ data: currentState.Survey.SurveyFormValue, page: currentState.Survey.page, selectedValue: currentState.Survey.selectedValue }))
            dispatch(getSurveyTabs({ data: currentState.Survey.SurveyFormValue, page: currentState.Survey.page, selectedValue: currentState.Survey.selectedValue }))
            dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            return response.data
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        }
});

export const dropTicket: any = createAsyncThunk(
    'dropTicket', async (thunkApi:any, {getState, dispatch} : any) => {
        try {
            const response: any = await axiosInstance(`operations/survey/drop_ticket`, {
                method: 'GET',
                params: { "ticket_id": thunkApi?.ticket_id, reason: thunkApi?.reason}
            })            
            const currentState: any = getState();
            response.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            response.status === 200 && dispatch(getSurveyData({data: currentState.Survey.SurveyFormValue, page: currentState.Survey.page, selectedValue: currentState.Survey.selectedValue}))
            response.status === 200 && dispatch(getSurveyTabs({data: currentState.Survey.SurveyFormValue, page: currentState.Survey.page, selectedValue: currentState.Survey.selectedValue}))
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
});
const SurveySlice = createSlice({
    name: 'Survey',
    initialState,
    reducers: {
        setSurveyForm: (state: any, payload: any) => {
            state.SurveyFormValue = payload?.payload
        },
        setDisplayValues: (state: any, payload: any) => {
            state.displayValues = payload?.payload
        },
        setCurrentRuleValue: (state: any, payload: any) => {
            state.currentRules = payload?.payload
        },
        setVendorDisplayValues: (state: any, payload: any) => {
            state.vendorDisplayValues = payload?.payload
        },
        setVendorCurrentRuleValue: (state: any, payload: any) => {
            state.vendorCurrentRules = payload?.payload
        },
        setChargerDisplayValues: (state: any, payload: any) => {
            state.chargerDisplayValues = payload?.payload
        },
        setChargerCurrentRuleValue: (state: any, payload: any) => {
            state.chargerCurrentRules = payload?.payload
        },
        setCityDisplayValues: (state: any, payload: any) => {
            state.CityDisplayValues = payload?.payload
        },
        setCityCurrentRuleValue: (state: any, payload: any) => {
            state.CityCurrentRules = payload?.payload
        },
        setStateDisplayValues: (state: any, payload: any) => {
            state.StateDisplayValues = payload?.payload
        },
        setStateCurrentRuleValue: (state: any, payload: any) => {
            state.StateCurrentRules = payload?.payload
        },
        setPages: (state: any, payload: any) => {
            state.page = payload?.payload
        },
        setSelectedValues: (state: any, payload: any) => {
            state.selectedValue = payload?.payload
        },
        setDealTab: (state: any, payload: any) => {
            state.SurveyDealTab = payload?.payload
        },
        setSelectedTab: (state: any, payload: any) => {
            state.selectedTab = payload?.payload
        },

    },
    extraReducers: (builder: any) => {
        return builder.addCase(addSurveyTicket.pending, (state: any) => {
            state.is_add_survey_ticket_loading = true
            state.is_add_survey_ticket_success = false
        }),
            builder.addCase(addSurveyTicket.fulfilled, (state: any, { payload }: any) => {
                state.is_add_survey_ticket_loading = false
                state.is_add_survey_ticket_success = true
            }),
            builder.addCase(addSurveyTicket.rejected, (state: any) => {
                state.is_add_survey_ticket_loading = false
                state.is_add_survey_ticket_success = false
                // state.EvApplicationDetail = []
            }),
            builder.addCase(getVendorList.pending, (state: any) => {
                state.VendorListLoading = true
            }),
            builder.addCase(getVendorList.fulfilled, (state: any, { payload }: any) => {
                state.VendorListLoading = false
                state.VendorList = payload
            }),
            builder.addCase(getSurveyTabs.pending, (state: any) => {
                state.SurveyTabsLoading = true
            }),
            builder.addCase(getSurveyTabs.fulfilled, (state: any, { payload }: any) => {
                state.SurveyTabsLoading = false
                state.SurveyTabsData = payload.data.message
            }),
            builder.addCase(getSurveyTabs.rejected, (state: any) => {
                state.SurveyTabsLoading = false
                state.SurveyTabsData = []
                state.DealDetailsData = []
            }),
            builder.addCase(getSurveyData.pending, (state: any) => {
                state.SurveyLoading = true
                state.totalPages = 1
            }),
            builder.addCase(getSurveyData.fulfilled, (state: any, { payload }: any) => {
                state.SurveyLoading = false
                state.SurveyData = payload
                state.addOpsCommentSuccess = false
                state.editOpsCommentSuccess = false
                state.deleteOpsCommentSuccess = false
                state.totalPages = payload?.totalPages
            }),
            builder.addCase(getSurveyData.rejected, (state: any) => {
                state.SurveyLoading = false
                state.SurveyData = []
                state.totalPages = 0
            }),
            builder.addCase(getTicketDetails.pending, (state: any) => {
                state.SurveyDetailLoading = true
                // state.editTicketStatusSuccess = false
                state.addPurchaseOrderSuccess = false
                state.addTransactionSuccess = false
                state.addPaymentTermSuccess = false
                state.verifyTransactionSuccess = false
            }),
            builder.addCase(getTicketDetails.fulfilled, (state: any, { payload }: any) => {
                state.SurveyDetailLoading = false
                state.editTicketStatusSuccess = false
                state.editTicketDetailsSuccess = false
                state.addPurchaseOrderSuccess = false
                state.addTransactionSuccess = false
                state.addPaymentTermSuccess = false
                state.verifyTransactionSuccess = false
                state.SurveyDealDetailData = payload
            }),
            builder.addCase(getTicketDetails.rejected, (state: any) => {
                state.SurveyDetailLoading = false
                state.SurveyDealDetailData = []
            }),
            builder.addCase(getTicketHistory.pending, (state: any) => {
                state.SurveyHistoryLoading = true
            }),
            builder.addCase(getTicketHistory.fulfilled, (state: any, { payload }: any) => {
                state.SurveyHistoryLoading = false
                state.SurveyHistoryData = payload
            }),
            builder.addCase(getTicketHistory.rejected, (state: any) => {
                state.SurveyHistoryLoading = false
                state.SurveyHistoryData = []
            }),
            builder.addCase(getOtherDetails.pending, (state: any) => {
                state.SurveyOthersLoading = true
            }),
            builder.addCase(getOtherDetails.fulfilled, (state: any, { payload }: any) => {
                state.SurveyOthersLoading = false
                state.SurveyOthersData = payload
            }),
            builder.addCase(getOtherDetails.rejected, (state: any) => {
                state.SurveyOthersLoading = false
                state.SurveyOthersData = []
            }),
            builder.addCase(getSkipStatuses.pending, (state: any) => {
                state.skipStatusLoading = true
            }),
            builder.addCase(getSkipStatuses.fulfilled, (state: any, { payload }: any) => {
                state.skipStatusLoading = false
                state.skipStatusData = payload
            }),
            builder.addCase(getSkipStatuses.rejected, (state: any) => {
                state.skipStatusLoading = false
                state.skipStatusData = []
            }),
            builder.addCase(getPaymentTime.pending, (state: any) => {
                state.PaymentTimeLoading = true
            }),
            builder.addCase(getPaymentTime.fulfilled, (state: any, { payload }: any) => {
                state.PaymentTimeLoading = false
                state.PaymentTimeData = payload
            }),
            builder.addCase(getPaymentTime.rejected, (state: any) => {
                state.PaymentTimeLoading = false
                state.PaymentTimeData = []
            }),
            builder.addCase(getPaymentConditions.pending, (state: any) => {
                state.PaymentConditionLoading = true
            }),
            builder.addCase(getPaymentConditions.fulfilled, (state: any, { payload }: any) => {
                state.PaymentConditionLoading = false
                state.PaymentConditionData = payload
            }),
            builder.addCase(getPaymentConditions.rejected, (state: any) => {
                state.PaymentConditionLoading = false
                state.PaymentConditionData = []
            }),
            builder.addCase(DropTicketStatus.pending, (state: any) => {
                state.DropTicketLoading = true
            }),
            builder.addCase(DropTicketStatus.fulfilled, (state: any, { payload }: any) => {
                state.DropTicketLoading = false
                state.PaymentConditionData = payload
            }),
            builder.addCase(DropTicketStatus.rejected, (state: any) => {
                state.DropTicketLoading = false
                state.PaymentConditionData = []
            }),
            builder.addCase(getButtonDropdown.pending, (state: any) => {
                state.SurveyButtonLoading = true
            }),
            builder.addCase(getButtonDropdown.fulfilled, (state: any, { payload }: any) => {
                state.SurveyButtonLoading = false
                state.SurveyButtonData = payload?.data
            }),
            builder.addCase(getButtonDropdown.rejected, (state: any) => {
                state.SurveyButtonLoading = false
                state.SurveyButtonData = []
            }),
            builder.addCase(deleteTransaction.pending, (state: any) => {
                state.deleteTransactionLoading = true
                state.deleteTransactionSuccess = false
            }),
            builder.addCase(deleteTransaction.fulfilled, (state: any, { payload }: any) => {
                state.deleteTransactionLoading = false
                state.deleteTransactionSuccess = true
            }),
            builder.addCase(deleteTransaction.rejected, (state: any) => {
                state.deleteTransactionLoading = false
                state.deleteTransactionSuccess = false
            }),
            builder.addCase(deletePaymentTerm.pending, (state: any) => {
                state.deletePaymentLoading = true
                state.deletePaymentSuccess = false
            }),
            builder.addCase(deletePaymentTerm.fulfilled, (state: any, { payload }: any) => {
                state.deletePaymentLoading = false
                state.deletePaymentSuccess = true
            }),
            builder.addCase(deletePaymentTerm.rejected, (state: any) => {
                state.deletePaymentLoading = false
                state.deletePaymentSuccess = false
            }),
            builder.addCase(addTicketDetails.pending, (state: any) => {
                state.addPurchaseOrderLoading = true
                state.addPurchaseOrderSuccess = false
            }),
            builder.addCase(addTicketDetails.fulfilled, (state: any) => {
                state.addPurchaseOrderLoading = false
                state.addPurchaseOrderSuccess = true
            }),
            builder.addCase(addTicketDetails.rejected, (state: any) => {
                state.addPurchaseOrderLoading = false
                state.addPurchaseOrderSuccess = false
            }),
            builder.addCase(addTransaction.pending, (state: any) => {
                state.addTransactionLoading = true
                state.addTransactionSuccess = false
            }),
            builder.addCase(addTransaction.fulfilled, (state: any) => {
                state.addTransactionLoading = false
                state.addTransactionSuccess = true
            }),
            builder.addCase(addTransaction.rejected, (state: any) => {
                state.addTransactionLoading = false
                state.addTransactionSuccess = false
            }),
            builder.addCase(VerifyTransaction.pending, (state: any) => {
                state.verifyTransactionLoading = true
                state.verifyTransactionSuccess = false
            }),
            builder.addCase(VerifyTransaction.fulfilled, (state: any) => {
                state.verifyTransactionLoading = false
                state.verifyTransactionSuccess = true
            }),
            builder.addCase(VerifyTransaction.rejected, (state: any) => {
                state.verifyTransactionLoading = false
                state.verifyTransactionSuccess = false
            }),
            builder.addCase(addPaymentTerm.pending, (state: any) => {
                state.addPaymentTermLoading = true
                state.addPaymentTermSuccess = false
            }),
            builder.addCase(addPaymentTerm.fulfilled, (state: any) => {
                state.addPaymentTermLoading = false
                state.addPaymentTermSuccess = true
            }),
            builder.addCase(addPaymentTerm.rejected, (state: any) => {
                state.addPaymentTermLoading = false
                state.addPaymentTermSuccess = false
            }),
            builder.addCase(editTicketDetails.pending, (state: any) => {
                state.editTicketDetailsLoading = true
                state.editTicketDetailsSuccess = false
            }),
            builder.addCase(editTicketDetails.fulfilled, (state: any) => {
                state.editTicketDetailsLoading = false
                state.editTicketDetailsSuccess = true
            }),
            builder.addCase(editTicketDetails.rejected, (state: any) => {
                state.editTicketDetailsSuccess = false
            }),
            builder.addCase(editSkipStatus.pending, (state: any) => {
                state.editSkipStatusLoading = true
                state.editSkipStatusSuccess = false
            }),
            builder.addCase(editSkipStatus.fulfilled, (state: any) => {
                state.editSkipStatusLoading = false
                state.editSkipStatusSuccess = true
            }),
            builder.addCase(editSkipStatus.rejected, (state: any) => {
                state.editSkipStatusSuccess = false
            }),
            builder.addCase(editTicketStatus.pending, (state: any) => {
                state.editTicketStatusLoading = true
            }),
            builder.addCase(editTicketStatus.fulfilled, (state: any) => {
                state.editTicketStatusLoading = false
                state.editTicketStatusSuccess = true
            }),
            builder.addCase(editTicketStatus.rejected, (state: any) => {
                state.editTicketStatusSuccess = false
            }),
            builder.addCase(dropTicket.pending, (state: any) => {
                state.getDropTicketLoading = true
                state.getDropTicketSuccess = false
                state.DropTicket = ""
            }),
            builder.addCase(dropTicket.fulfilled, (state: any, { payload }: any) => {
                state.getDropTicketLoading = false
                state.getDropTicketSuccess = true
                state.DropTicket = payload
            }),
            builder.addCase(dropTicket.rejected, (state: any) => {
                state.getDropTicketLoading = false
                state.getDropTicketSuccess = false
                state.DropTicket = ""
            }),
            builder.addCase(getSurveyDetails.pending, (state: any) => {
                state.SurveyDetailsLoading = true
            }),
            builder.addCase(getSurveyDetails.fulfilled, (state: any, { payload }: any) => {
                state.SurveyDetailsLoading = false
                state.SurveyDetailsData = payload;
                state.editTicketStatusSuccess = false
                state.SurveyButtonDropdownSuccess= false
            }),
            builder.addCase(getSurveyDetails.rejected, (state: any) => {
                state.SurveyDetailsLoading = false
                state.SurveyDetailsData = [];
            }),
            builder.addCase(getSurveyButtonDropdown.fulfilled, (state: any, { payload }: any) => {
                state.SurveyButtonDropdownLoading = false
                state.SurveyButtonDropdownSuccess = true
                state.SurveyButtonDropdownData = payload
            }),
            builder.addCase(getSurveyButtonDropdown.rejected, (state: any) => {
                state.SurveyButtonDropdownLoading = false
                state.SurveyButtonDropdownData = []
            }),
            builder.addCase(getSurveyButtonDropdown.pending, (state: any) => {
                state.SurveyButtonDropdownLoading = true
                state.SurveyButtonDropdownData = []
            }),
            builder.addCase(editSurveyDetails.pending, (state: any) => {
                state.editSurveyDetailsLoading = true;
            }),
            builder.addCase(editSurveyDetails.fulfilled, (state: any, { payload }: any) => {
                state.editSurveyDetailsLoading = false;
                state.editSurveyDetailSuccess = true
            }),
            builder.addCase(editSurveyDetails.rejected, (state: any) => {
                state.editSurveyDetailsLoading = false;
                state.editSurveyDetailSuccess = false
            }),
            builder.addCase(getSurveyHistory.pending, (state: any) => {
                state.SurveyHistoryLoading = true
            }),
            builder.addCase(getSurveyHistory.fulfilled, (state: any, { payload }: any) => {
                state.SurveyHistoryLoading = false
                state.SurveyHistoryData = payload
            }),
            builder.addCase(getSurveyHistory.rejected, (state: any) => {
                state.SurveyHistoryLoading = false
            })
    }
})

export const { setVendorCurrentRuleValue, setVendorDisplayValues, setDisplayValues, setChargerCurrentRuleValue, setChargerDisplayValues, setCurrentRuleValue, setSurveyForm,
    setCityCurrentRuleValue, setCityDisplayValues, setStateCurrentRuleValue, setStateDisplayValues, setPages, setSelectedValues,
    setSelectedTab, setDealTab} = SurveySlice.actions
export const getAllUser = (state: any) => state.users.loading
export const addUserSuccess = (state: any) => state.users.addSuccess

export default SurveySlice.reducer