import React, { useEffect, useRef, useState } from 'react'
import styles from './MultiSelectDropdown.module.scss'
import downArrow from "../../assets/images/down_arrow.svg"
import useOutsideElementAlerter from '../../hooks/useOutsideElementAlerter'
import close from "../../assets/images/close_icon.svg"
import SearchInputV2 from '../SearchInputV2/SearchInputV2'

type MultiSelectDropdownProp = {
  title: any,
  options: any,
  id?: any,
  onChange?: any,
  selectedOptions: any,
  disabled?: boolean,
  selectionType?: string,
  onSelect?: any,
  height?: string,
  width?: string,
  setDropdownOpen?: any,
  fontsize?: string
  selectStyle?: React.CSSProperties,
  dropdownHeight?: string,
  error?: boolean,
  showError?: boolean,
  dropdownPadding?: string,
  optionMargin?: string,
  hideSelectAll?: boolean,
  titleColor?: string
  // height?:any
}

function MultiSelectDropdown(props: MultiSelectDropdownProp) {
  const { title, options, id, onChange, selectedOptions, disabled, onSelect, titleColor, selectStyle, height, width, dropdownHeight, fontsize, error, showError, dropdownPadding, optionMargin } = props
  const [showOptions, setShowOptions] = useState(false);
  const [optionSelected, setOptionsSelected] = useState([...selectedOptions]);
  const [searchTerm, setSearchTerm] = useState("");
  const wrapperRef = useRef(null);
  useOutsideElementAlerter(wrapperRef, setShowOptions);

  useEffect(() => { setOptionsSelected([...selectedOptions]) }, [selectedOptions])

  const onChnageHandler = (option: any) => {
    // let clone = JSON.parse(JSON.stringify(optionSelected));
    let clone = [...optionSelected]
    let index = clone.findIndex((op: any) => {
      return op.id === option.id
    })

    if (index > -1) {
      clone.splice(index, 1)
    }
    else {
      clone.push(option);
    }

    if (props.selectionType === "single")
      onChange(clone);
    else {
      setOptionsSelected(clone);
      onSelect(clone)
    }
  }
  const selectAllHandler = () => {
    if (optionSelected?.filter((option: any) => { return option?.title?.toLowerCase()?.includes(searchTerm?.toLocaleLowerCase()) })?.length < options?.filter((option: any) => { return option?.title?.toLowerCase()?.includes(searchTerm?.toLocaleLowerCase()) })?.length) {
      setOptionsSelected([...options]?.filter((option: any) => { return option?.title?.toLowerCase()?.includes(searchTerm?.toLocaleLowerCase()) }))
      onSelect([...options]?.filter((option: any) => { return option?.title?.toLowerCase()?.includes(searchTerm?.toLocaleLowerCase()) }))
    }
    else {
      setOptionsSelected([])
      onSelect([])
    }
  }

  const getFilteredOptions = () => {
    let filteredOptions = options.filter((option: any) =>
      option?.title?.toLowerCase()?.includes(searchTerm?.toLocaleLowerCase())
    );
    if (filteredOptions.length) {
      return filteredOptions.map((option: any, index: any) => (
        <div
          className={styles.drop_down_option}
          style={{ marginBottom: optionMargin }}
          key={option?.id}
          onClick={() => {
            if (props.selectionType !== "single") return;
            props?.onSelect(option);
            setShowOptions(false);
          }}
        >
          <div>
            {props.selectionType !== "single" && (
              <input
                type="checkbox"
                checked={
                  optionSelected.findIndex((op: any) => {
                    return option.id === op.id;
                  }) > -1
                }
                value={option?.title}
                onChange={(e: any) => {
                  onChnageHandler(option);
                }}
              />
            )}
          </div>
          {option?.graphicTitle ? (
            <span>{option?.graphicTitle}</span>
          ) : (
            <span>
              {option?.display
                ? option?.display
                : option?.title
                  ? option?.title
                  : option?.name}
            </span>
          )}
        </div>
      ));
    } else {
      return <span className={styles.drop_down_option}> No matching options... </span>
    }
  };

  useEffect(() => {
    if (props?.setDropdownOpen) {
      props?.setDropdownOpen(showOptions)
    }
    setSearchTerm("")
  }, [showOptions])
  return (
    <div ref={wrapperRef} className={styles.dropdown_container} style={{ ...selectStyle, cursor: disabled ? "not-allowed" : "", opacity: disabled ? 0.5 : 1 }}>
      <div id={id} className={styles.dropdown_container_body} onClick={() => { if (disabled || showOptions || options.length === 0) return; setShowOptions(true) }} style={{ height: height || "", width: width || "", border: showError && error ? "1px solid #FC441E" : "" }}>
        {selectedOptions.filter((option: any) => { return option?.title?.toLowerCase()?.includes(searchTerm?.toLocaleLowerCase()) })?.length ? <div className={styles.selceted_options_container}>
          {
            props.selectionType === "single" ?
              <div className={styles.selceted_option} style={{ fontSize: fontsize }}>{selectedOptions?.[0]?.title !== undefined ? selectedOptions?.[0]?.title : selectedOptions?.[0]?.name}</div> :
              <>
                {
                  optionSelected?.filter((option: any) => { return option?.title?.toLowerCase()?.includes(searchTerm?.toLocaleLowerCase()) })?.map((option: any) => {
                    return <div className={styles.selceted_options} key={option.id}>{option.title?.[0]}{option.title?.[1]}{option.title?.[2]}{option.title?.[3]}...<img onClick={(e: any) => { e.stopPropagation(); onChnageHandler(option) }} className={styles.close_icon} src={close} alt="close" /></div>
                  })
                }
              </>
          }
        </div> :
          <span className={styles.body_title} style={{ fontSize: fontsize, color: titleColor }}>{title}</span>}
        <img
          // onClick={()=>{if(disabled) return; setShowOptions(true)}} 
          className={styles.body_down_arrow}
          src={downArrow}
          alt="down-arrow"
          style={{ transform: showOptions ? "rotate(180deg)" : "" }}
        />
      </div>
      {
        showOptions ? <div className={styles.drop_down} style={{ height: dropdownHeight, overflow: "scroll", paddingTop: dropdownPadding, paddingBottom: dropdownPadding }}>
          <div style={{ padding: "5px" }}>
            <SearchInputV2 placeholder={`${title}`} onSearch={(value: string) => { setSearchTerm(value) }} value={searchTerm} />
          </div>
          {
            !props?.hideSelectAll && props.selectionType !== "single" && options?.length > 0 && <div className={styles.drop_down_option} onClick={selectAllHandler}>
              <input type="checkbox" checked={optionSelected?.filter((option: any) => { return option?.title?.toLowerCase()?.includes(searchTerm?.toLocaleLowerCase()) })?.length === options?.filter((option: any) => { return option?.title?.toLowerCase()?.includes(searchTerm?.toLocaleLowerCase()) })?.length} />
              Select All
            </div>
          }
          {
            getFilteredOptions()
          }
        </div> : <></>
      }
    </div>
  )
}

export default MultiSelectDropdown