import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { setAlertFunc } from '../../../../components/AlertToaster/AlertSlice';
import { axiosInstance } from '../../../Auth/components/axiosInterceptors'

const initialState = {
    profileData: [],
    Loading: false,
    editLoading: false,
    editSuccess: false,
    otp_tokens: '',
    verifyLoginLoadings: false,
    verifyUsers: [],
    verifySuccess: false,
    loginLoading: false,
    loginSuccess: false,
    resendSuccess: false,
    resendLoading: false,
}

export const getProfile = createAsyncThunk(
    'getProfile', async (_, thunkAPI) => {
        try {
            const response = await axiosInstance.get(`settings/listprofile`);
            return await response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    })

export const getVerify = createAsyncThunk(
    'profile-otp',
    async (obj: any, { thunkAPI, dispatch }: any) => {

        try {
            const res: any = await axiosInstance(`login/verify-otp`, {
                method: 'POST',
                data: JSON.stringify(obj),
            }).then(
                (data: any) => {
                    return data;
                }
            )
            res?.data?.status === 401 && obj.setInvalidOTP(true)
            if (res?.data?.status === 201) {
                obj.toggle()
                obj.setPhoneValue(obj.phone)
            }
            return res.data
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    })

export const editProfile: any = createAsyncThunk('editReasons',
    async (thunkAPI: any, { dispatch, getState }: any) => {
        try {
            const res = await axiosInstance('settings/editprofile', {
                method: 'PUT',
                data: JSON.stringify(thunkAPI),
                headers: {
                    "otp-token": getState()?.Profile?.otp_tokens
                }
            })
            dispatch(getProfile())
            dispatch(setAlertFunc({ type: 'success', msg: res?.data?.message }))
            return res.data
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
)
export const sendOTP: any = createAsyncThunk(
    'login/send-otp',
    async (phone: any, thunkApi: any) => {
        const response = await axiosInstance(`login/send-otp`, {
            method: 'POST',
            data: JSON.stringify(phone),
        }).then(
            (data: any) => {
                return data;
            }
        )
        return response
    }
);
export const resendOTP: any = createAsyncThunk(
    'Verifylogin/send-otp',
    async (phone: any, thunkApi: any) => {
        const response: any = await axiosInstance(`login/resend-otp`, {
            method: 'POST',
            data: JSON.stringify(phone),
        }).then(
            (data: any) => {
                return data;
            }
        )
        return response
    }
);
const ProfileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {},
    extraReducers: (builder: any) => {
        return builder.addCase(getProfile.pending, (state: any) => {
            state.Loading = true
            state.profileData = []
        }),
            builder.addCase(getProfile.fulfilled, (state: any, { payload }: any) => {
                state.Loading = false
                state.profileData = payload
            }),
            builder.addCase(getProfile.rejected, (state: any) => {
                state.Loading = false
                state.profileData = []
            }),
            builder.addCase(getVerify.pending, (state: any) => {
                state.verifyLoginLoadings = true
                state.verifySuccess = false

            }),
            builder.addCase(getVerify.fulfilled, (state: any, { payload }: any) => {
                state.verifyLoginLoadings = false
                state.otp_tokens = payload?.token?.otp_token
                state.verifySuccess = true
                state.verifyUsers = payload
            }),
            builder.addCase(getVerify.rejected, (state: any) => {
                state.verifyLoginLoadings = false
                state.verifySuccess = false

            }),
            builder.addCase(editProfile.pending, (state: any) => {
                state.editLoading = true
            }),
            builder.addCase(editProfile.fulfilled, (state: any) => {
                state.editLoading = false
                state.editSuccess = true
            }),
            builder.addCase(editProfile.rejected, (state: any) => {
                state.editLoading = false
            }),
            builder.addCase(sendOTP.pending, (state: any) => {
                state.loginLoading = true
                state.loginSuccess = false

            }),
            builder.addCase(sendOTP.fulfilled, (state: any) => {
                state.loginLoading = false
                state.loginSuccess = true
            }),
            builder.addCase(sendOTP.rejected, (state: any) => {
                state.loginLoading = false
                state.loginSuccess = false
            }),
            builder.addCase(resendOTP.pending, (state: any) => {
                state.resendLoading = true
                state.resendSuccess = false

            }),
            builder.addCase(resendOTP.fulfilled, (state: any) => {
                state.resendLoading = false
                state.resendSuccess = true
            }),
            builder.addCase(resendOTP.rejected, (state: any) => {
                state.resendLoading = false
                state.resendSuccess = false
            })
    }
})
export default ProfileSlice.reducer
