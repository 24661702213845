import axios from "axios";
import { getTime } from "date-fns";
import { access } from "fs";
import { useDispatch, useSelector } from 'react-redux'
import urls from "../../../enums/urlList";
import { getRefreshToken } from "./verifyLoginSlice";

export const axiosInstance = axios.create({
  baseURL: urls.base_url,
  paramsSerializer: (params: any) => {
    return Object.entries(Object.assign({}, params)).
      map(([key, value]) => `${key}=${value}`).
      join('&');
  }
});

let refreshTokenPromise: Promise<any> | null = null;

const CreateAxiosResponseInterceptor = () => {
  const { refreshToken, isAuth, user_id } = useSelector((store: any) => store.verifyLogin)
  const dispatch = useDispatch<any>();
  let isRefreshing = false;

  axiosInstance.interceptors.request.use(
    (config: any) => {

      if (isAuth === true && localStorage.getItem("access_token") !== "" && user_id !== 0) {
        config.headers["Auth-Token"] = localStorage.getItem("access_token");

        if (config.method === 'get') {

          config.params = { ...config.params, 'user_id': user_id };
        }
        if (config.url == `settings/upload_report_formats` && config.method === 'post') {
          config.headers["Content-Type"] = "multipart/form-data";
        }
        if (config.url == `proc_mgmt/ticket` && config.method === 'put') {
          config.headers["Content-Type"] = "multipart/form-data";
        }

        else if (config.method === 'put' || config.method === 'post' || config.method === 'patch') {
          config.headers["Content-Type"] = "application/json";
          if ((config.url == `${urls.base_url}operations/ticket` || config.url == `${urls.base_url}operations/demolitions/ticket` || config.url == `${urls.base_url}operations/survey/ticket_put` || config.url == `${urls.base_url}operations/ev_application` || config.url == `${urls.base_url}operations/edit_deal_details` || config.url == `${urls.base_url}logistics/ticket_details` || config.url == `${urls.base_url}network_reliability/ticket` ||
            config.url == `${urls.base_url}logistics/ticket` || config.url == `${urls.base_url}logistics/skip_to_status` || config.url == `${urls.base_url}finance/ticket` || config.url == `${urls.base_url}customer_support/ticket` || config.url == `${urls.base_url}onboarding/cpo/ticket` || config.url == `${urls.base_url}onboarding/investors/ticket` ||
            config.url == `${urls.base_url}onboarding/stations/ticket` || config.url == `${urls.base_url}onboarding/host/ticket` || config.url == `${urls.base_url}onboarding/host/ticket_details` || config.url == `${urls.base_url}operations/other_details` || config.url == `${urls.base_url}finance/tax_invoice` ||
            config.url === `${urls.base_url}operations/survey/link_survey` || config.url === `${urls.base_url}operations/edit_vendor` || config.url === `${urls.base_url}operations/site_ready_for_dispatch` || config.url === `${urls.base_url}onboarding/investors/ticket_details` || config.url == `${urls.base_url}logistics/edit_solo_ticket` ||
            config.url == `${urls.base_url}logistics/drop_solo_ticket` || config.url == `${urls.base_url}operations/demolitions/charger_details` || config.url == `${urls.base_url}operations/demolitions/ticket_details` || config.url == `${urls.base_url}logistics/link_solo_log` || config.url == `${urls.base_url}operations/survey/ticket_details` || config.url == `${urls.base_url}operations/completion_date` || config.url == `${urls.base_url}operations/survey/edit_image`) && config.method === 'put') {
            config.headers["Content-Type"] = "multipart/form-data";
          }
          else if ((config.url == `${urls.base_url}operations/ticket` || config.url == `${urls.base_url}operations/delivery_date` || config.url == `${urls.base_url}operations/comment` || config.url == `${urls.base_url}proc_mgmt/comment` || config.url == `${urls.base_url}operations/ev_application` ||
            config.url == `${urls.base_url}logistics/ticket_details` || config.url == `${urls.base_url}operations/dochub` || config.url == `${urls.base_url}operations/survey/request_survey` || config.url == `${urls.base_url}operations/demolitions/ticket` || config.url == `${urls.base_url}operations/survey/ticket_details`) && config.method === 'post') {
            config.headers["Content-Type"] = "multipart/form-data";
          }
          else if ((config.url == `${urls.base_url}settings/upload_report_formats` || config.url == `comments/comment` ||
            config.url == `${urls.base_url}finance/transaction` || config.url == `${urls.base_url}finance/payment_term` || config.url == `${urls.base_url}finance/verify_transaction` || config.url == `${urls.base_url}onboarding/cpo/ticket_details` || config.url == `${urls.base_url}onboarding/host/ticket_details` || config.url == `${urls.base_url}onboarding/stations/ticket_details` || config.url == `${urls.base_url}finance/tax_invoice`) && config.method === 'post') {
            config.headers["Content-Type"] = "multipart/form-data";
          }
          else if ((config.url == `${urls.base_url}projects/tickets` || config.url == `${urls.base_url}onboarding/investors/ticket` || config.url == `${urls.base_url}onboarding/investors/admin_api` || config.url == `${urls.base_url}logistics/ticket` || config.url == `${urls.base_url}operations/upload_dr`) && config.method === 'post') {
            config.headers["Content-Type"] = "multipart/form-data";
          }
          else if ((config.url == `${urls.base_url}projects/tickets`) && config.method === 'patch') {
            config.headers["Content-Type"] = "multipart/form-data";
          }
          else {
            if (config?.data) {
              const obj = typeof config?.data === "object" ? config?.data : JSON.parse(config?.data)
              obj['user_id'] = user_id
              config.data = JSON.stringify(obj)
            }
          }
        }
      }
      return config;
    },
    (error: any) => {


      return Promise.reject(error);
    }
  );

  const interceptor = axiosInstance.interceptors.response.use(function (response: any) {
    return response;
  }, async function (error: any) {
    if (error.response) {
      if (error.response.status === 401) {
        if (!isRefreshing) {
          isRefreshing = true;
          let obj: any = {
            refreshToken: localStorage.getItem("refresh_token"),
            id: user_id,
          };

          if (obj.refreshToken !== null && user_id !== 0) {
            refreshTokenPromise = new Promise(async (resolve, reject) => {
              await dispatch(
                getRefreshToken(obj),
                (success: any) => {
                  isRefreshing = false;
                  resolve(success);
                },
                (error: any) => {
                  console.log('Refresh login error: ', error);
                  isRefreshing = false;
                  reject(error);
                }
              );
            });
          }
        }

        if (refreshTokenPromise) {
          try {
            await refreshTokenPromise;
          } catch (refreshError) {
            return Promise.reject(refreshError);
          }
        }
      }
      if (error.response.status === 403) {
        localStorage.clear();
        window.location.replace('/auth');
        return Promise.reject(error.response.data)

      }
      if (error.response.status === 400) {
        return Promise.reject(error.response.data);
      }
    }
    axiosInstance.interceptors.response.eject(interceptor);

    return Promise.reject(error);
  }

  );

}

export default CreateAxiosResponseInterceptor