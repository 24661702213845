import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../../.../../../Auth/components/axiosInterceptors';
import { setAlertFunc } from '../../../../components/AlertToaster/AlertSlice';

const initialState = {
    loading: false,
    editNotificationLoading: false,
    editSuccess: false,
    notificationData: []
}


export const getNotification: any = createAsyncThunk(
    'getNotification', async (_, thunkApi: any) => {
        try {
            const response: any = await axiosInstance(`notifications/acl`, {
                method: 'GET',
                data: JSON.stringify(thunkApi)
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });

    export const editNotification: any = createAsyncThunk(
        'editNotification', async (thunkApi: any, { dispatch }) => {
            try {
                const response: any = await axiosInstance(`notifications/acl`, {
                    method: 'PUT',
                    data: JSON.stringify(thunkApi)
                })
                dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
                dispatch(getNotification())
                return response.data
            }
            catch (error: any) {
                dispatch(setAlertFunc({ type: 'error', msg: error.message }))
                return thunkApi.rejectWithValue({ error: error.message });
            }
        })

const NotificationSlice = createSlice({
    name: 'NotificationSlice',
    initialState,
    reducers: {},
    extraReducers: (builder: any) => {
        return builder.addCase(getNotification.pending, (state: any) => {
            state.loading = true
            state.notificationData = []
        }),
            builder.addCase(getNotification.fulfilled, (state: any, { payload }: any) => {
                state.loading = false
                state.notificationData = payload
            }),
            builder.addCase(getNotification.rejected, (state: any) => {
                state.loading = false
                state.notificationData = []
            }),
            builder.addCase(editNotification.pending, (state: any) => {
                state.editNotificationLoading = true
                state.editSuccess = false
            }),
            builder.addCase(editNotification.fulfilled, (state: any) => {
                state.editNotificationLoading = false
                state.editSuccess = true
            }),
            builder.addCase(editNotification.rejected, (state: any) => {
                state.editNotificationLoading = false
            })
    },
})

export default NotificationSlice.reducer;