import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from "@reduxjs/toolkit/query/react"
import { TStore } from "../store/Store"

const rawBaseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
        const store = getState() as TStore
        const { isAuth, user_id } = store.verifyLogin
        const token = localStorage.getItem("access_token")
        if (isAuth && user_id && token) {
            headers.set("Auth-Token", token)
        }
        return headers
    },
    paramsSerializer: (params: Record<string, any>) => {
        return Object.entries(Object.assign({}, params))
            .map(([key, value]) => `${key}=${value}`)
            .join("&")
    },
})

const customBaseQuery: BaseQueryFn<FetchArgs, unknown, FetchBaseQueryError> = (args, api, extraOptions) => {
    const store = api.getState() as TStore
    const { user_id } = store.verifyLogin

    if (!user_id) {
        return rawBaseQuery(args, api, extraOptions)
    }

    let modifiedArgs: FetchArgs = { ...args }

    if (args.method === "GET" || api.type === "query") {
        modifiedArgs = {
            ...args,
            params: { ...args.params, user_id },
        }
    }

    if (api.type === "mutation") {
        if (args.body instanceof FormData) {
            const newFormData = new FormData()
            args.body.forEach((value, key) => newFormData.append(key, value))
            newFormData.append("user_id", user_id)
            modifiedArgs.body = newFormData
        } else
            modifiedArgs.body = {
                ...args.body,
                user_id,
            }
    }

    return rawBaseQuery(modifiedArgs, api, extraOptions)
}

export const api = createApi({
    baseQuery: customBaseQuery,
    endpoints: () => ({}),
    tagTypes: [
        "Projects",
        "Installation",
        "EvApplication",
        "Survey",
        "Logistics",
        "Finance",
        "Stations",
        "CPO",
        "Host",
        "Investor",
        "Distributors",
        "Transporters",
        "OpVendors",
        "Admin",
        "Settings",
        "TechSupport",
        "CustomerSupport",
    ],
})
