import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance } from '../Auth/components/axiosInterceptors'
import urls from "../../enums/urlList";
import { setAlertFunc } from '../../components/AlertToaster/AlertSlice'

type LogisticsFormValue = {
    cityId: any;
    stateId: any;
    destinationcityId: any;
    destinationstateId: any;
    chargerIds: any;
    statusId: any;
    age_op: string;
    age: any;
    more_than_age: string;
    between_age1: string;
    between_age2: string;
    less_than_age: string;
    assigned_to: Record<string, any>;
    TicketStartDate: string;
    TicketEndDate: string;
    ETDStartDate: string;
    ETDEndDate: string;
    search_key: string;
    sow: string;
    ticketType:string
    delivery_vendor: Record<string, any>;
    parent_status: string;
}

type InitialState = {
    users: any[],
    loading: boolean,
    addSuccess: boolean,
    totalPages: number,
    page: number,
    selectedValue: number,
    addLoading: boolean,
    editLoading: boolean,
    EditSuccess: boolean,
    chargerDisplayValues:any[],
    chargerCurrentRules:any[],
    StateDisplayValues:any[],
    StateCurrentRules:any[],
    CityDisplayValues:any[],
    CityCurrentRules:any[],
    DestinationStateDisplayValues:any[],
    DestinationStateCurrentRules:any[],
    DestinationCityDisplayValues:any[],
    DestinationCityCurrentRules:any[],
    currentRules:any[],
    displayValues:any[],
    LogisticsFormValue: LogisticsFormValue;
    addLogisticsTicketForm: {
        source_site_id: Record<string, any>;
        source_latitude: string;
        source_longitude: string;
        source_address: string;
        source_city: Record<string, any>;
        source_state: Record<string, any>;
        source_pincode: string;
        source_poc_name: string;
        source_poc_contact: string;
        source_poc_email: string;
        delivery_request: string;
        time: string;
        project_name:string,
        delivery_date: string;
        destination_site_id: Record<string, any>;
        destination_latitude: string;
        destination_longitude: string;
        destination_address: string;
        destination_city: Record<string, any>;
        destination_state: Record<string, any>;
        destination_pincode: string;
        destination_poc_name: string;
        destination_poc_contact: string;
        destination_poc_email: string;
    },
    DeliveryVendorLoading: boolean,
    DeliveryVendorData: any[],
    LogisticsTabsLoading: boolean,
    LogisticsTabsData: any[],
    LogisticsLoading: boolean,
    LogisticsData: any,
    LogisticsDetailLoading: boolean,
    LogisticsDealDetailData: any,
    LogisticsHistoryLoading: boolean,
    LogisticsHistoryData:any,
    LogisticsSiteIdLoading: boolean,
    LogisticsSiteIdData:any[],
    SourceAutofillLoading: boolean,
    SourceAutofillData:any[],
    DestinationAutofillLoading: boolean,
    DestinationAutofillData:any[],
    LogisticOthersLoading: boolean,
    LogisticsOthersData:any,
    LogisticButtonLoading: boolean,
    LogisticButtonData:any[],
    deletePurchaseLoading: boolean,
    deletePurchaseSuccess: boolean,
    addPurchaseOrderSuccess: boolean,
    addPurchaseOrderLoading: boolean,
    addSoloTicketSuccess: boolean,
    addSoloTicketLoading: boolean,
    editTicketDetailsSuccess: boolean,
    editTicketDetailsLoading: boolean,
    editTicketFormSuccess: boolean,
    editTicketFormLoading: boolean,
    DropLogisticDealSuccess: boolean,
    DropLogisticDealLoading: boolean,
    editTicketStatusSuccess: boolean,
    editTicketStatusLoading: boolean,
    skipStatusLoading: boolean,
    skipStatusData:any,
    editSkipStatusSuccess: boolean,
    editSkipStatusLoading: boolean,
    ChargerTemplateLoading: boolean,
    ChargerTemplateData:any[],
    LogisticsDealTab: number,
    selectedTab: number,
    DocumentTemplateLoading: boolean,
    DocumentTemplateData:any[],
    LogisticsProjectIdData:any[],
    LogisticsProjectIdLoading: boolean,
    editProjectLinkSuccess: boolean,
    editProjectLinkLoading: boolean,
    addOpsCommentSuccess: boolean,
    editOpsCommentSuccess: boolean,
    deleteOpsCommentSuccess: boolean
}

const initialState: InitialState = {
    users: [],
    loading: false,
    addSuccess: false,
    totalPages: 1,
    page: 1,
    selectedValue: 10,
    addLoading: false,
    editLoading: false,
    EditSuccess: false,
    chargerDisplayValues: [],
    chargerCurrentRules: [],
    StateDisplayValues: [],
    StateCurrentRules: [],
    CityDisplayValues: [],
    CityCurrentRules: [],
    DestinationStateDisplayValues: [],
    DestinationStateCurrentRules: [],
    DestinationCityDisplayValues: [],
    DestinationCityCurrentRules: [],
    currentRules: [],
    displayValues: [],
    LogisticsFormValue: {
        cityId: [],
        stateId: [],
        destinationcityId: [],
        destinationstateId: [],
        chargerIds: [],
        statusId: [],
        age_op: '',
        age: [],
        more_than_age: '',
        between_age1: '',
        between_age2: '',
        less_than_age: '',
        assigned_to: {},
        TicketStartDate: '',
        TicketEndDate: '',
        ETDStartDate: '',
        ETDEndDate: '',
        search_key: '',
        ticketType:'all',
        sow: '',
        delivery_vendor: {},
        parent_status: 'all_tab'
    },
    addLogisticsTicketForm: {
        source_site_id: {},
        source_latitude: '',
        source_longitude: '',
        source_address: '',
        source_city: {},
        source_state: {},
        source_pincode: '',
        source_poc_name: '',
        source_poc_contact: '',
        source_poc_email: '',
        delivery_request: '',
        time: '',
        project_name:'',
        delivery_date: '',
        destination_site_id: {},
        destination_latitude: '',
        destination_longitude: '',
        destination_address: '',
        destination_city: {},
        destination_state: {},
        destination_pincode: '',
        destination_poc_name: '',
        destination_poc_contact: '',
        destination_poc_email: '',
    },
    DeliveryVendorLoading: false,
    DeliveryVendorData: [],
    LogisticsTabsLoading: false,
    LogisticsTabsData: [],
    LogisticsLoading: false,
    LogisticsData: [] as any,
    LogisticsDetailLoading: false,
    LogisticsDealDetailData: [] as any,
    LogisticsHistoryLoading: false,
    LogisticsHistoryData: [],
    LogisticsSiteIdLoading: false,
    LogisticsSiteIdData: [],
    SourceAutofillLoading: false,
    SourceAutofillData: [],
    DestinationAutofillLoading: false,
    DestinationAutofillData: [],
    LogisticOthersLoading: false,
    LogisticsOthersData: [],
    LogisticButtonLoading: false,
    LogisticButtonData: [],
    deletePurchaseLoading: false,
    deletePurchaseSuccess: false,
    addPurchaseOrderSuccess: false,
    addPurchaseOrderLoading: false,
    addSoloTicketSuccess: false,
    addSoloTicketLoading: false,
    editTicketDetailsSuccess: false,
    editTicketDetailsLoading: false,
    editTicketFormSuccess: false,
    editTicketFormLoading: false,
    DropLogisticDealSuccess: false,
    DropLogisticDealLoading: false,
    editTicketStatusSuccess: false,
    editTicketStatusLoading: false,
    skipStatusLoading: false,
    skipStatusData: [],
    editSkipStatusSuccess: false,
    editSkipStatusLoading: false,
    ChargerTemplateLoading: false,
    ChargerTemplateData: [],
    LogisticsDealTab: 0,
    selectedTab: 0,
    DocumentTemplateLoading: false,
    DocumentTemplateData: [],
    LogisticsProjectIdData: [],
    LogisticsProjectIdLoading: false,
    editProjectLinkSuccess: false,
    editProjectLinkLoading: false,
    addOpsCommentSuccess: false,
    editOpsCommentSuccess: false,
    deleteOpsCommentSuccess: false
}

export const getLogisticsTabs: any = createAsyncThunk(
    'getLogisticsTabs', async (obj: any, thunkApi) => {
        try {
            const response: any = await axiosInstance(`logistics/total`, {
                method: 'GET',
                params: {
                    'source_state_id': JSON.stringify(obj.data.stateId ?? []),
                    'destination_state_id': JSON.stringify(obj.data.destinationstateId ?? []),
                    'status_id': JSON.stringify(obj.data.statusId ?? []),
                    "source_city_id": JSON.stringify(obj.data.cityId ?? []),
                    'destination_city_id': JSON.stringify(obj.data.destinationcityId ?? []),
                    'assigned_to': obj.data.assigned_to?.id ?? '',
                    'vendor_id': obj.data.delivery_vendor?.id ?? '',
                    'age': JSON.stringify(obj.data.age ?? []),
                    'age_op': obj.data.age_op ?? '',
                    'raised_on_end': obj.data.TicketEndDate ?? '',
                    'raised_on_start': obj.data.TicketStartDate ?? '',
                    'etd_start': obj.data.ETDStartDate ?? '',
                    'etd_end': obj.data.ETDEndDate ?? '',
                    'charger_type': JSON.stringify(obj.data.chargerIds ?? []),
                    'search_key': obj.data.search_key ?? '',
                    'raised_by_id': obj.data.raised_by_id ?? '',
                    'read_filter': obj.data.ReadUnread ?? 'all',
                    'scope_of_work': obj.data.sow ?? '',
                    'ticket_type': obj.data.ticketType ?? 'all',
                    'per_page': obj?.selectedValue,
                    'page': obj?.page
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const getLogisticsData: any = createAsyncThunk(
    'getLogisticsData', async (obj: any, { thunkApi, dispatch }: any) => {
        try {
            const response: any = await axiosInstance(`logistics/${obj.data.parent_status ?? 'all_tab'}`, {
                method: 'GET',
                params: {
                    'source_state_id': JSON.stringify(obj.data.stateId ?? []),
                    'destination_state_id': JSON.stringify(obj.data.destinationstateId ?? []),
                    'status_id': JSON.stringify(obj.data.statusId ?? []),
                    "source_city_id": JSON.stringify(obj.data.cityId ?? []),
                    'destination_city_id': JSON.stringify(obj.data.destinationcityId ?? []),
                    'assigned_to': obj.data.assigned_to?.id ?? '',
                    'vendor_id': obj.data.delivery_vendor?.id ?? '',
                    'age': JSON.stringify(obj.data.age ?? []),
                    'age_op': obj.data.age_op ?? '',
                    'raised_on_end': obj.data.TicketEndDate ?? '',
                    'raised_on_start': obj.data.TicketStartDate ?? '',
                    'etd_start': obj.data.ETDStartDate ?? '',
                    'etd_end': obj.data.ETDEndDate ?? '',
                    'charger_type': JSON.stringify(obj.data.chargerIds ?? []),
                    'search_key': obj.data.search_key ?? '',
                    'raised_by_id': obj.data.raised_by_id ?? '',
                    'read_filter': obj.data.ReadUnread ?? 'all',
                    'ticket_type': obj.data.ticketType ?? 'all',
                    'scope_of_work': obj.data.sow ?? '',
                    'per_page': obj?.selectedValue,
                    'page': obj?.page
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });

export const getTicketDetails: any = createAsyncThunk(
    'ticketDetail', async (ticket_id: any, thunkApi: any) => {
        try {
            const response: any = await axiosInstance(`logistics/ticket_details`, {
                method: 'GET',
                params: {
                    "ticket_id": ticket_id ?? '',
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const getDeliveryVendor: any = createAsyncThunk(
    'getDeliveryVendor', async (__, thunkApi: any) => {
        try {
            const response: any = await axiosInstance(`logistics/delivery_vendors`, {
                method: 'GET'
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const getTicketSiteId: any = createAsyncThunk(
    'getTicketSiteId', async (__, thunkApi: any) => {
        try {
            const response: any = await axiosInstance(`logistics/site_id`, {
                method: 'GET'
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const getProjectLinkId: any = createAsyncThunk(
    'getProjectLinkId', async (__, thunkApi: any) => {
        try {
            const response: any = await axiosInstance(`logistics/projects_list`, {
                method: 'GET'
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const getSourceSoloAutofill: any = createAsyncThunk(
    'getSourceSoloAutofill', async (id: any, thunkApi: any) => {
        try {
            const response: any = await axiosInstance(`logistics/form_autofill`, {
                method: 'GET',
                params: {
                    "id": id ?? '',
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const getDestinationSoloAutofill: any = createAsyncThunk(
    'getDestinationSoloAutofill', async (id: any, thunkApi: any) => {
        try {
            const response: any = await axiosInstance(`logistics/form_autofill`, {
                method: 'GET',
                params: {
                    "id": id ?? '',
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const getTicketHistory: any = createAsyncThunk(
    'history', async (ticket_id: any, thunkApi: any) => {
        try {
            const response: any = await axiosInstance(`logistics/history`, {
                method: 'GET',
                params: {
                    "ticket_id": ticket_id ?? '',
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const getOtherDetails: any = createAsyncThunk(
    'getOtherDetails', async (ticket_id: any, thunkApi: any) => {
        try {
            const response: any = await axiosInstance(`logistics/other_details`, {
                method: 'GET',
                params: {
                    "ticket_id": ticket_id ?? '',
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const getSkipStatuses: any = createAsyncThunk(
    'getStatuses', async (obj: any, thunkApi) => {
        try {
            const response: any = await axiosInstance(`logistics/statuses`, {
                method: 'GET',
                params: {
                    "search_key": obj.search_key ?? '',
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const getButtonDropdown: any = createAsyncThunk(
    'getButtonDropdown', async (ticket_id: any, thunkApi) => {
        try {
            const response: any = await axiosInstance(`logistics/button`, {
                method: 'GET',
                params: {
                    "ticket_id": ticket_id ?? '',
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const getChargerTemplate: any = createAsyncThunk(
    'getChargerTemplate', async (__, thunkApi) => {
        try {
            const response: any = await axiosInstance(`logistics/charger_template`, {
                method: 'GET',
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });

export const addTicketDetails: any = createAsyncThunk(
    'addFormat',
    async (thunkAPI: any, { getState, dispatch }: any) => {

        try {
            const response: any = await axiosInstance(`${urls.base_url}logistics/ticket_details`, {
                method: 'POST',
                data: thunkAPI.data,
            })
            response.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            response.status === 200 && dispatch(getTicketDetails(thunkAPI.data.get("ticket_id")))
            if (response.status === 200 && thunkAPI.toggle !== undefined) {
                thunkAPI.toggle();
            }
            return response.data
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);
export const addSoloTicket: any = createAsyncThunk(
    'addSoloTicket',
    async (thunkAPI: any, { getState, dispatch }: any) => {

        try {
            const response: any = await axiosInstance(`${urls.base_url}logistics/ticket`, {
                method: 'POST',
                data: thunkAPI.data,
            })
            const currentState: any = getState();
            response.status === 201 && dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            response.status === 201 && dispatch(getLogisticsData({ data: currentState.Logistics.LogisticsFormValue, page: currentState.Logistics.page, selectedValue: currentState.Logistics.selectedValue }))
            response.status === 201 && dispatch(getLogisticsTabs({ data: currentState.Logistics.LogisticsFormValue, page: currentState.Logistics.page, selectedValue: currentState.Logistics.selectedValue }))
            if (response.status === 201 && thunkAPI.toggle !== undefined) {
                thunkAPI.toggle();
            }
            return response.data
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);
export const editTicketDetails: any = createAsyncThunk(
    'editTicketDetails', async (thunkApi: any, { getState, dispatch }: any) => {
        try {
            const response: any = await axiosInstance(`${urls.base_url}logistics/ticket_details`, {
                method: 'PUT',
                data: thunkApi.data,
            });
            response.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            response.status === 200 && dispatch(getTicketDetails(thunkApi.data.get("ticket_id")))
            if (response.status === 200 && thunkApi.toggle !== undefined) {
                thunkApi.toggle();
            }
            return response.data;
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const editProjectLink: any = createAsyncThunk(
    'editProjectLink', async (thunkApi: any, { getState, dispatch }: any) => {
        try {
            const response: any = await axiosInstance(`${urls.base_url}logistics/link_solo_log`, {
                method: 'PUT',
                data: thunkApi.data,
            });
            response.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            response.status === 200 && dispatch(getTicketDetails(thunkApi.data.get("ticket_id")))
            response.status === 200 && dispatch(getButtonDropdown(thunkApi.data.get("ticket_id")))
            if (response.status === 200 && thunkApi.toggle !== undefined) {
                thunkApi.toggle();
            }
            return response.data;
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const getGsrTemplate: any = createAsyncThunk(
    'getGsrTemplate', async (__, thunkApi) => {
        try {
            const response: any = await axiosInstance(`logistics/gsr_template`, {
                method: 'GET'
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const editTicketForm: any = createAsyncThunk(
    'editTicketForm', async (thunkApi: any, { getState, dispatch }) => {
        try {
            const response: any = await axiosInstance(`${urls.base_url}logistics/edit_solo_ticket`, {
                method: 'PUT',
                data: thunkApi.data,
            });
            const currentState: any = getState();

            response.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            response.status === 200 && dispatch(getLogisticsData({ data: currentState.Logistics.LogisticsFormValue, page: currentState.Logistics.page, selectedValue: currentState.Logistics.selectedValue }))
            response.status === 200 && dispatch(getLogisticsTabs({ data: currentState.Logistics.LogisticsFormValue, page: currentState.Logistics.page, selectedValue: currentState.Logistics.selectedValue }))
            if (response.status === 200 && thunkApi.toggle !== undefined) {
                thunkApi.toggle();
            }
            return response.data;
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const DropLogisticDeal: any = createAsyncThunk(
    'DropLogisticDeal', async (thunkApi: any, { getState, dispatch }) => {
        try {
            const response: any = await axiosInstance(`${urls.base_url}logistics/drop_solo_ticket`, {
                method: 'PUT',
                data: thunkApi.data,
            });
            const currentState: any = getState();

            response.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            response.status === 200 && dispatch(getLogisticsData({ data: currentState.Logistics.LogisticsFormValue, page: currentState.Logistics.page, selectedValue: currentState.Logistics.selectedValue }))
            response.status === 200 && dispatch(getLogisticsTabs({ data: currentState.Logistics.LogisticsFormValue, page: currentState.Logistics.page, selectedValue: currentState.Logistics.selectedValue }))
            if (response.status === 200 && thunkApi.toggle !== undefined) {
                thunkApi.toggle();
            }
            return response.data;
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });

export const editSkipStatus: any = createAsyncThunk(
    'editSkipStatus', async (thunkApi: any, { getState, dispatch }: any) => {
        try {
            const response: any = await axiosInstance(`${urls.base_url}logistics/skip_to_status`, {
                method: 'PUT',
                data: thunkApi.data,
            });
            response.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            response.status === 200 && dispatch(getTicketDetails(thunkApi.data.get("ticket_id")))
            response.status === 200 && dispatch(getButtonDropdown(thunkApi.data.get("ticket_id")))
            return response.data;
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });

export const editTicketStatus: any = createAsyncThunk(
    'editTicketStatus', async (thunkApi: any, { getState, dispatch }: any) => {
        try {
            const response: any = await axiosInstance(`${urls.base_url}logistics/ticket`, {
                method: 'PUT',
                data: thunkApi.data,
            });
            response.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            response.status === 200 && dispatch(getTicketDetails(thunkApi.data.get("ticket_id")))
            response.status === 200 && dispatch(getButtonDropdown(thunkApi.data.get("ticket_id")))
            if (response.status === 200 && thunkApi.toggle !== undefined) {
                thunkApi.toggle();
            }
            return response.data;
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const deletePurchaseOrder: any = createAsyncThunk(
    'deletePurchaseOrder', async (thunkApi: any, { getState, dispatch }) => {
        const response: any = await axiosInstance(`logistics/ticket_details?ticket_id=${thunkApi?.ticket_id}&user_id=${thunkApi?.user_id}&purchase_orders=${thunkApi?.purchase_orders}`, {
            method: 'DELETE',
        }).then(
            (data: any) => {
                dispatch(getTicketDetails(thunkApi?.ticket_id))
                data.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: data?.data?.message }))
                return data
            }
        ).catch((error: any) => {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        })
        return response
    });
const LogisticsSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setProcurementForm: (state, payload) => {
            state.LogisticsFormValue = payload?.payload
        },
        setDisplayValues: (state, payload) => {
            state.displayValues = payload?.payload
        },
        setCurrentRuleValue: (state, payload) => {
            state.currentRules = payload?.payload
        },
        setChargerDisplayValues: (state, payload) => {
            state.chargerDisplayValues = payload?.payload
        },
        setChargerCurrentRuleValue: (state, payload) => {
            state.chargerCurrentRules = payload?.payload
        },
        setCityDisplayValues: (state, payload) => {
            state.CityDisplayValues = payload?.payload
        },
        setCityCurrentRuleValue: (state, payload) => {
            state.CityCurrentRules = payload?.payload
        },
        setStateDisplayValues: (state, payload) => {
            state.StateDisplayValues = payload?.payload
        },
        setStateCurrentRuleValue: (state, payload) => {
            state.StateCurrentRules = payload?.payload
        },
        setDestinationCityDisplayValues: (state, payload) => {
            state.DestinationCityDisplayValues = payload?.payload
        },
        setDestinationCityCurrentRuleValue: (state, payload) => {
            state.DestinationCityCurrentRules = payload?.payload
        },
        setDestinationStateDisplayValues: (state, payload) => {
            state.DestinationStateDisplayValues = payload?.payload
        },
        setDestinationStateCurrentRuleValue: (state, payload) => {
            state.DestinationStateCurrentRules = payload?.payload
        },
        setPages: (state, payload) => {
            state.page = payload?.payload
        },
        setSelectedValues: (state, payload) => {
            state.selectedValue = payload?.payload
        },
        setDealTab: (state, payload) => {
            state.LogisticsDealTab = payload?.payload
        },
        setSelectedTab: (state, payload) => {
            state.selectedTab = payload?.payload
        },
        setAddLogisticsTicketForm: (state, payload) => {
            state.addLogisticsTicketForm = payload?.payload
        },

    },
    extraReducers: (builder) => {
        return builder.addCase(getLogisticsTabs.pending, (state) => {
            state.LogisticsTabsLoading = true
            state.addSoloTicketSuccess = false
        }),
            builder.addCase(getLogisticsTabs.fulfilled, (state, { payload }) => {
                state.LogisticsTabsLoading = false
                state.LogisticsTabsData = payload.data.message
                state.addSoloTicketSuccess = false
            }),
            builder.addCase(getLogisticsTabs.rejected, (state) => {
                state.LogisticsTabsLoading = false
                state.LogisticsTabsData = []
                state.LogisticsDealDetailData = []
            }),
            builder.addCase(getLogisticsData.pending, (state) => {
                state.LogisticsLoading = true
                state.totalPages = 1
            }),
            builder.addCase(getLogisticsData.fulfilled, (state, { payload }) => {
                state.LogisticsLoading = false
                state.LogisticsData = payload
                state.addOpsCommentSuccess = false
                state.editOpsCommentSuccess = false
                state.deleteOpsCommentSuccess = false
                state.editTicketFormSuccess = false
                state.totalPages = payload?.totalPages
            }),
            builder.addCase(getLogisticsData.rejected, (state) => {
                state.LogisticsLoading = false
                state.LogisticsData = []
                state.totalPages = 0
            }),
            builder.addCase(getTicketDetails.pending, (state) => {
                state.LogisticsDetailLoading = true
                // state.editTicketStatusSuccess = false
                state.addPurchaseOrderSuccess = false
            }),
            builder.addCase(getTicketDetails.fulfilled, (state, { payload }) => {
                state.LogisticsDetailLoading = false
                state.editTicketStatusSuccess = false
                state.editSkipStatusSuccess = false
                state.addPurchaseOrderSuccess = false
                state.LogisticsDealDetailData = payload
            }),
            builder.addCase(getTicketDetails.rejected, (state) => {
                state.LogisticsDetailLoading = false
                state.LogisticsDealDetailData = []
            }),
            builder.addCase(getTicketHistory.pending, (state) => {
                state.LogisticsHistoryLoading = true
            }),
            builder.addCase(getTicketHistory.fulfilled, (state, { payload }) => {
                state.LogisticsHistoryLoading = false
                state.LogisticsHistoryData = payload
            }),
            builder.addCase(getTicketHistory.rejected, (state) => {
                state.LogisticsHistoryLoading = false
                state.LogisticsHistoryData = []
            }),
            builder.addCase(getTicketSiteId.pending, (state) => {
                state.LogisticsSiteIdLoading = true
            }),
            builder.addCase(getTicketSiteId.fulfilled, (state, { payload }) => {
                state.LogisticsSiteIdLoading = false
                state.LogisticsSiteIdData = payload?.data
            }),
            builder.addCase(getTicketSiteId.rejected, (state) => {
                state.LogisticsSiteIdLoading = false
                state.LogisticsSiteIdData = []
            }),
            builder.addCase(getProjectLinkId.pending, (state) => {
                state.LogisticsProjectIdLoading = true
            }),
            builder.addCase(getProjectLinkId.fulfilled, (state, { payload }) => {
                state.LogisticsProjectIdLoading = false
                state.LogisticsProjectIdData = payload?.data
            }),
            builder.addCase(getProjectLinkId.rejected, (state) => {
                state.LogisticsProjectIdLoading = false
                state.LogisticsProjectIdData = []
            }),
            builder.addCase(getDeliveryVendor.pending, (state) => {
                state.DeliveryVendorLoading = true
            }),
            builder.addCase(getDeliveryVendor.fulfilled, (state, { payload }) => {
                state.DeliveryVendorLoading = false
                state.DeliveryVendorData = payload?.data
            }),
            builder.addCase(getDeliveryVendor.rejected, (state) => {
                state.DeliveryVendorLoading = false
                state.DeliveryVendorData = []
            }),
            builder.addCase(getGsrTemplate.pending, (state) => {
                state.DocumentTemplateLoading = true
            }),
            builder.addCase(getGsrTemplate.fulfilled, (state, { payload }) => {
                state.DocumentTemplateLoading = false
                state.DocumentTemplateData = payload
            }),
            builder.addCase(getGsrTemplate.rejected, (state) => {
                state.DocumentTemplateLoading = false
                state.DocumentTemplateData = []
            }),
            builder.addCase(getSourceSoloAutofill.pending, (state) => {
                state.SourceAutofillLoading = true
            }),
            builder.addCase(getSourceSoloAutofill.fulfilled, (state, { payload }) => {
                state.SourceAutofillLoading = false
                state.SourceAutofillData = payload?.data
            }),
            builder.addCase(getSourceSoloAutofill.rejected, (state) => {
                state.SourceAutofillLoading = false
                state.SourceAutofillData = []
            }),
            builder.addCase(getDestinationSoloAutofill.pending, (state) => {
                state.DestinationAutofillLoading = true
            }),
            builder.addCase(getDestinationSoloAutofill.fulfilled, (state, { payload }) => {
                state.DestinationAutofillLoading = false
                state.DestinationAutofillData = payload?.data
            }),
            builder.addCase(getDestinationSoloAutofill.rejected, (state) => {
                state.DestinationAutofillLoading = false
                state.DestinationAutofillData = []
            }),
            builder.addCase(getOtherDetails.pending, (state) => {
                state.LogisticOthersLoading = true
            }),
            builder.addCase(getOtherDetails.fulfilled, (state, { payload }) => {
                state.LogisticOthersLoading = false
                state.LogisticsOthersData = payload
            }),
            builder.addCase(getOtherDetails.rejected, (state) => {
                state.LogisticOthersLoading = false
                state.LogisticsOthersData = []
            }),
            builder.addCase(getSkipStatuses.pending, (state) => {
                state.skipStatusLoading = true
            }),
            builder.addCase(getSkipStatuses.fulfilled, (state, { payload }) => {
                state.skipStatusLoading = false
                state.skipStatusData = payload
            }),
            builder.addCase(getSkipStatuses.rejected, (state) => {
                state.skipStatusLoading = false
                state.skipStatusData = []
            }),
            builder.addCase(getChargerTemplate.pending, (state) => {
                state.ChargerTemplateLoading = true
            }),
            builder.addCase(getChargerTemplate.fulfilled, (state, { payload }) => {
                state.ChargerTemplateLoading = false
                state.ChargerTemplateData = payload
            }),
            builder.addCase(getChargerTemplate.rejected, (state) => {
                state.ChargerTemplateLoading = false
                state.ChargerTemplateData = []
            }),
            builder.addCase(getButtonDropdown.pending, (state) => {
                state.LogisticButtonLoading = true
            }),
            builder.addCase(getButtonDropdown.fulfilled, (state, { payload }) => {
                state.LogisticButtonLoading = false
                state.LogisticButtonData = payload?.data
            }),
            builder.addCase(getButtonDropdown.rejected, (state) => {
                state.LogisticButtonLoading = false
                state.LogisticButtonData = []
            }),
            builder.addCase(deletePurchaseOrder.pending, (state) => {
                state.deletePurchaseLoading = true
                state.deletePurchaseSuccess = false
            }),
            builder.addCase(deletePurchaseOrder.fulfilled, (state, { payload }) => {
                state.deletePurchaseLoading = false
                state.deletePurchaseSuccess = true
            }),
            builder.addCase(deletePurchaseOrder.rejected, (state) => {
                state.deletePurchaseLoading = false
                state.deletePurchaseSuccess = false
            }),
            builder.addCase(addTicketDetails.pending, (state) => {
                state.addPurchaseOrderLoading = true
                state.addPurchaseOrderSuccess = false
            }),
            builder.addCase(addTicketDetails.fulfilled, (state) => {
                state.addPurchaseOrderLoading = false
                state.addPurchaseOrderSuccess = true
            }),
            builder.addCase(addTicketDetails.rejected, (state) => {
                state.addPurchaseOrderLoading = false
                state.addPurchaseOrderSuccess = false
            }),
            builder.addCase(addSoloTicket.pending, (state) => {
                state.addSoloTicketLoading = true
                state.addSoloTicketSuccess = false
            }),
            builder.addCase(addSoloTicket.fulfilled, (state) => {
                state.addSoloTicketLoading = false
                state.addSoloTicketSuccess = true
            }),
            builder.addCase(addSoloTicket.rejected, (state) => {
                state.addSoloTicketLoading = false
                state.addSoloTicketSuccess = false
            }),
            builder.addCase(editTicketDetails.pending, (state) => {
                state.editTicketDetailsLoading = true
                state.editTicketDetailsSuccess = false
            }),
            builder.addCase(editTicketDetails.fulfilled, (state) => {
                state.editTicketDetailsLoading = false
                state.editTicketDetailsSuccess = true
            }),
            builder.addCase(editTicketDetails.rejected, (state) => {
                state.editTicketDetailsSuccess = false
            }),
            builder.addCase(editProjectLink.pending, (state) => {
                state.editProjectLinkLoading = true
                state.editProjectLinkSuccess = false
            }),
            builder.addCase(editProjectLink.fulfilled, (state) => {
                state.editProjectLinkLoading = false
                state.editProjectLinkSuccess = true
            }),
            builder.addCase(editProjectLink.rejected, (state) => {
                state.editProjectLinkSuccess = false
            }),
            builder.addCase(editTicketForm.pending, (state) => {
                state.editTicketFormLoading = true
                state.editTicketFormSuccess = false
            }),
            builder.addCase(editTicketForm.fulfilled, (state) => {
                state.editTicketFormLoading = false
                state.editTicketFormSuccess = true
            }),
            builder.addCase(editTicketForm.rejected, (state) => {
                state.editTicketFormSuccess = false
            }),
            builder.addCase(DropLogisticDeal.pending, (state) => {
                state.DropLogisticDealLoading = true
                state.editTicketFormSuccess = false
            }),
            builder.addCase(DropLogisticDeal.fulfilled, (state) => {
                state.DropLogisticDealLoading = false
                state.DropLogisticDealSuccess = true
            }),
            builder.addCase(DropLogisticDeal.rejected, (state) => {
                state.DropLogisticDealSuccess = false
            }),
            builder.addCase(editSkipStatus.pending, (state) => {
                state.editSkipStatusLoading = true
                state.editSkipStatusSuccess = false
            }),
            builder.addCase(editSkipStatus.fulfilled, (state) => {
                state.editSkipStatusLoading = false
                state.editSkipStatusSuccess = true
            }),
            builder.addCase(editSkipStatus.rejected, (state) => {
                state.editSkipStatusSuccess = false
            }),
            builder.addCase(editTicketStatus.pending, (state) => {
                state.editTicketStatusLoading = true
                state.editTicketStatusSuccess = false
            }),
            builder.addCase(editTicketStatus.fulfilled, (state) => {
                state.editTicketStatusLoading = false
                state.editTicketStatusSuccess = true
            }),
            builder.addCase(editTicketStatus.rejected, state => {
                state.editTicketStatusLoading = false
                state.editTicketStatusSuccess = false
            })
    }
})

export const { setDisplayValues, setChargerCurrentRuleValue, setChargerDisplayValues, setCurrentRuleValue, setProcurementForm,
    setCityCurrentRuleValue, setCityDisplayValues, setStateCurrentRuleValue, setStateDisplayValues, setPages, setSelectedValues,
    setSelectedTab, setDealTab, setAddLogisticsTicketForm, setDestinationCityCurrentRuleValue, setDestinationCityDisplayValues,
    setDestinationStateCurrentRuleValue, setDestinationStateDisplayValues } = LogisticsSlice.actions
export const getAllUser = (state: any) => state.users.loading
export const addUserSuccess = (state: any) => state.users.addSuccess

export default LogisticsSlice.reducer