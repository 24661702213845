import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance } from '../Auth/components/axiosInterceptors'
import urls from "../../enums/urlList";
import { setAlertFunc } from '../../components/AlertToaster/AlertSlice'

const initialState = {
    addSuccess: false,
    totalPages: 1,
    page: 1,
    selectedValue: 10,
    CPODealTab: 0,
    CPOselectedTab: 0,
    CPOFormValue: {
        cityId: [],
        stateId: [],
        chargerIds: [],
        statusId: [],
        age_op: '',
        age: [],
        oemId: '',
        more_than_age: '',
        between_age1: '',
        between_age2: '',
        less_than_age: '',
        assigned_to: '',
        TicketStartDate: '',
        TicketEndDate: '',
        search_key: '',
        sow: '',
        parent_status: 'all_tab'
    },
    CPOTabsLoading: false,
    CPOTabsData: [],
    CPOLoading: false,
    CPOData: [],
    /* */
    InvestorDealTab: 0,
    InvestorselectedTab: 0,
    InvestorFormValue: {
        cityId: [],
        stateId: [],
        chargerIds: [],
        statusId: [],
        age_op: '',
        age: [],
        profit_op: '',
        profit_type:'',
        profit: [],
        oemId: '',
        more_than_age: '',
        between_age1: '',
        between_age2: '',
        less_than_age: '',
        assigned_to: '',
        addedByIds:[],
        TicketStartDate: '',
        TicketEndDate: '',
        search_key: '',
        sow: '',
        parent_status: 'all_tab'
    },
    addInvestorFormValue: {
        company_name: '',
        city_id: '',
        state_id: '',
        address: '',
        gst_no: '',
        chargers: [],
        bank_details: '',
        admin_name: '',
        admin_phone: '',
        admin_email: '',
        poc_name: '',
        poc_email: '',
        poc_phone: ''
    },
    editInvestorFormValue: {
        company_name: '',
        city_id: '',
        state_id: '',
        address: '',
        gst_no: '',
        chargers: [],
        bank_details: '',
        admin_name: '',
        admin_phone: '',
        admin_email: '',
        poc_name: '',
        poc_email: '',
        poc_phone: ''
    },
    InvestorChargers: [],
    InvestorTabsLoading: false,
    InvestorTabsData: [],
    InvestorLoading: false,
    InvestorData: [],
    addInvestorLoading: false,
    addInvestorSuccess: false,
    addInvestorAdminLoading: false,
    addInvestorAdminSuccess: false,
    editInvestorTicketStatusSuccess:false,
    editInvestorTicketStatusLoading:false,
    deleteAdminSuccess:false,
    deleteAdminLoading:false,
    addedByData:[],
    addedByLoading:false,
    /*  */
    StationsFormValue: {
        cityId: [],
        stateId: [],
        chargerIds: [],
        statusId: [],
        age_op: '',
        age: [],
        oemId: '',
        more_than_age: '',
        between_age1: '',
        between_age2: '',
        less_than_age: '',
        assigned_to: '',
        TicketStartDate: '',
        TicketEndDate: '',
        search_key: '',
        sow: '',
        parent_status: 'all_tab',
        connectorType: ''
    },
    StationsTabsLoading: false,
    StationsTabsData: [],
    StationsLoading: false,
    StationsData: [],
    StationselectedTab: 0,
    StationDealTab: 0,

    /* */
    HostFormValue: {
        cityId: [],
        stateId: [],
        chargerIds: [],
        statusId: [],
        age_op: '',
        age: [],
        oemId: '',
        more_than_age: '',
        between_age1: '',
        between_age2: '',
        less_than_age: '',
        assigned_to: '',
        TicketStartDate: '',
        TicketEndDate: '',
        search_key: '',
        sow: '',
        cpoId: '',
        parent_status: 'all_tab'
    },
    HostselectedTab: 0,
    HostDealTab: 0,
    HostTabsLoading: false,
    HostTabsData: [],
    HostLoading: false,
    HostData: [],
    /* */

    chargerDisplayValues: [],
    chargerCurrentRules: [],
    addedByDisplayValues: [],
    addedByCurrentRules: [],
    StateDisplayValues: [],
    StateCurrentRules: [],
    CityDisplayValues: [],
    CityCurrentRules: [],
    currentRules: [],
    displayValues: [],
    CPOButtonLoading: false,
    CPOButtonData: [],
    CPOHistoryData: [],
    CPOHistoryLoading: false,
    CPODetailLoading: false,
    CPODealDetailData: [],
    /* */
    InvestorButtonLoading: false,
    InvestorButtonData: [],
    InvestorHistoryData: [],
    InvestorHistoryLoading: false,
    InvestorDetailLoading: false,
    InvestorDealDetailData: [],
    InvestorProfitData: [],
    InvestorProfitLoading: false,
    editInvestorSuccess:false,
    editInvestorLoading:false,
    /*  */
    StationsButtonLoading: false,
    StationsButtonData: [],
    StationsHistoryData: [],
    StationsHistoryLoading: false,
    StationsDetailLoading: false,
    StationsDealDetailData: [],
    /*  */
    HostButtonLoading: false,
    HostButtonData: [],
    HostHistoryData: [],
    HostHistoryLoading: false,
    HostDetailLoading: false,
    HostDealDetailData: [],
    /* */
    addAdminSuccess: false,
    addAdminLoading: false,
    editTicketStatusSuccess: false,
    editTicketStatusLoading: false,
    skipStatusLoading: false,
    skipStatusData: [],
    editTicketDetailsSuccess: false,
    editTicketDetailsLoading: false,
    CpoPage: 1,
    InvestorPage: 1,
    HostPage: 1
}

export const getCPOTabs: any = createAsyncThunk(
    'getCPOTabs', async (obj: any, thunkApi) => {
        try {
            const response: any = await axiosInstance(`onboarding/cpo/total`, {
                method: 'GET',
                params: {
                    'state_id': JSON.stringify(obj.data.stateId ?? []),
                    'status_ids': JSON.stringify(obj.data.statusId ?? []),
                    "city_id": JSON.stringify(obj.data.cityId ?? []),
                    'assigned_to': obj.data.assigned_to?.id ?? '',
                    'age': JSON.stringify(obj.data.age ?? []),
                    'age_op': obj.data.age_op ?? '',
                    'raised_on_end': obj.data.TicketEndDate ?? '',
                    'raised_on_start': obj.data.TicketStartDate ?? '',
                    'charger_type': JSON.stringify(obj.data.chargerIds ?? []),
                    'read_filter': obj.data.ReadUnread ?? 'all',
                    'search_key': obj.data.search_key ?? '',
                    'per_page': obj?.selectedValue,
                    'oem_id': obj.data.oemId?.id ?? '',
                    'page': obj?.page
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const getCPOData: any = createAsyncThunk(
    'getCPOData', async (obj: any, { thunkApi, dispatch }: any) => {
        try {
            const response: any = await axiosInstance(`onboarding/cpo/ticket/${obj.data.parent_status ?? 'all_tab'}`, {
                method: 'GET',
                params: {
                    'state_id': JSON.stringify(obj.data.stateId ?? []),
                    'status_ids': JSON.stringify(obj.data.statusId ?? []),
                    'oem_id': obj.data.oemId?.id ?? '',
                    "city_id": JSON.stringify(obj.data.cityId ?? []),
                    'assigned_to': obj.data.assigned_to?.id ?? '',
                    'age': JSON.stringify(obj.data.age ?? []),
                    'age_op': obj.data.age_op ?? '',
                    'raised_on_end': obj.data.TicketEndDate ?? '',
                    'read_filter': obj.data.ReadUnread ?? 'all',
                    'raised_on_start': obj.data.TicketStartDate ?? '',
                    'charger_type': JSON.stringify(obj.data.chargerIds ?? []),
                    'search_key': obj.data.search_key ?? '',
                    'per_page': obj?.selectedValue,
                    'page': obj?.page
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });

export const getCPOButtonDropdown: any = createAsyncThunk(
    'getCPOButtonDropdown', async (ticket_id: any, thunkApi) => {
        try {
            const response: any = await axiosInstance(`onboarding/cpo/button`, {
                method: 'GET',
                params: {
                    "ticket_id": ticket_id ?? '',
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
    export const getAddedBy: any = createAsyncThunk(
        'getAddedBy', async (__, thunkApi) => {
            try {
                const response: any = await axiosInstance(`onboarding/investors/added_by`, {
                    method: 'GET'
                })
                return response.data;
            } catch (error: any) {
                return thunkApi.rejectWithValue({ error: error.message });
            }
        });

export const getInvestorTabs: any = createAsyncThunk(
    'getInvestorTabs', async (obj: any, thunkApi) => {
        try {
            const response: any = await axiosInstance(`onboarding/investors/total`, {
                method: 'GET',
                params: {
                    'state_id': JSON.stringify(obj.data.stateId ?? []),
                    'status_ids': JSON.stringify(obj.data.statusId ?? []),
                    "city_id": JSON.stringify(obj.data.cityId ?? []),
                    'assigned_to': obj.data.assigned_to?.id ?? '',
                    'age': JSON.stringify(obj.data.age ?? []),
                    'age_op': obj.data.age_op ?? '',
                    'profit': JSON.stringify(obj.data.profit ?? []),
                    'profit_op': obj.data.profit_op ?? '',
                    'profit_type': obj.data.profit_type ?? '',
                    'raised_on_end': obj.data.TicketEndDate ?? '',
                    'raised_on_start': obj.data.TicketStartDate ?? '',
                    'charger_type': JSON.stringify(obj.data.chargerIds ?? []),
                    'added_by_ids': JSON.stringify(obj.data.addedByIds ?? []),
                    'read_filter': obj.data.ReadUnread ?? 'all',
                    'search_key': obj.data.search_key ?? '',
                    'per_page': obj?.selectedValue,
                    'page': obj?.page
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const getInvestorData: any = createAsyncThunk(
    'getInvestorData', async (obj: any, { thunkApi, dispatch }: any) => {
        try {
            const response: any = await axiosInstance(`onboarding/investors/ticket/${obj.data.parent_status ?? 'all_tab'}`, {
                method: 'GET',
                params: {
                    'state_id': JSON.stringify(obj.data.stateId ?? []),
                    'status_ids': JSON.stringify(obj.data.statusId ?? []),
                    "city_id": JSON.stringify(obj.data.cityId ?? []),
                    'assigned_to': obj.data.assigned_to?.id ?? '',
                    'age': JSON.stringify(obj.data.age ?? []),
                    'age_op': obj.data.age_op ?? '',
                    'profit': JSON.stringify(obj.data.profit ?? []),
                    'profit_op': obj.data.profit_op ?? '',
                    'profit_type': obj.data.profit_type ?? '',
                    'raised_on_end': obj.data.TicketEndDate ?? '',
                    'read_filter': obj.data.ReadUnread ?? 'all',
                    'raised_on_start': obj.data.TicketStartDate ?? '',
                    'charger_type': JSON.stringify(obj.data.chargerIds ?? []),
                    'added_by_ids': JSON.stringify(obj.data.addedByIds ?? []),
                    'search_key': obj.data.search_key ?? '',
                    'per_page': obj?.selectedValue,
                    'page': obj?.page
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });

export const getInvestorButtonDropdown: any = createAsyncThunk(
    'getInvestorButtonDropdown', async (ticket_id: any, thunkApi) => {
        try {
            const response: any = await axiosInstance(`onboarding/investors/button`, {
                method: 'GET',
                params: {
                    "ticket_id": ticket_id ?? '',
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const getInvestorProfit: any = createAsyncThunk(
    'getInvestorProfits', async (__, thunkApi) => {
        try {
            const response: any = await axiosInstance(`onboarding/investors/profit_type`, {
                method: 'GET'
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const addInvestor: any = createAsyncThunk(
    'addInvestor',
    async (thunkAPI: any, { getState, dispatch }: any) => {

        try {
            const response: any = await axiosInstance(`${urls.base_url}onboarding/investors/ticket`, {
                method: 'POST',
                data: thunkAPI.data,
            })
            const currentState: any = getState();
            response.status === 201 && dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            response.status === 201 && dispatch(getInvestorTabs({ data: currentState.Onboarding.addInvestorFormValue, page: currentState.Onboarding.page, selectedValue: currentState.Onboarding.selectedValue }))
            response.status === 201 && dispatch(getInvestorData({ data: currentState.Onboarding.addInvestorFormValue, page: currentState.Onboarding.page, selectedValue: currentState.Onboarding.selectedValue }))
            if (response.status === 201 && thunkAPI.toggle !== undefined) {
                thunkAPI.toggle();
            }
            return response.data
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);
export const getInvestorTicketDetails: any = createAsyncThunk(
    'getInvestorTicketDetails', async (ticket_id: any, thunkApi: any) => {
        try {
            const response: any = await axiosInstance(`onboarding/investors/ticket_details`, {
                method: 'GET',
                params: {
                    "ticket_id": ticket_id ?? '',
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const addInvestorAdmin: any = createAsyncThunk(
    'addInvestorAdmin',
    async (thunkAPI: any, { getState, dispatch }: any) => {
        try {
            const response: any = await axiosInstance(`${urls.base_url}onboarding/investors/admin_api`, {
                method: 'POST',
                data: thunkAPI.data,
            })
            response.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            response.status === 200 && dispatch(getInvestorTicketDetails(thunkAPI.data.get("ticket_id")))
            if (response.status === 200 && thunkAPI.toggle !== undefined) {
                thunkAPI.toggle();
            }
            return response.data
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);
export const getInvestorTicketHistory: any = createAsyncThunk(
    'getInvestorTicketHistory', async (ticket_id: any, thunkApi: any) => {
        try {
            const response: any = await axiosInstance(`onboarding/investors/history`, {
                method: 'GET',
                params: {
                    "ticket_id": ticket_id ?? '',
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const editInvestor: any = createAsyncThunk(
    'editInvestor', async (thunkApi: any, { getState, dispatch }: any) => {
        try {
            const response: any = await axiosInstance(`${urls.base_url}onboarding/investors/ticket_details`, {
                method: 'PUT',
                data: thunkApi.data,
            });
            response.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            response.status === 200 && dispatch(getInvestorTicketDetails(thunkApi.data.get("ticket_id")))
            if (response.status === 200 && thunkApi.toggle !== undefined) {
                thunkApi.toggle();
            }
            return response.data;
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
    export const editInvestorTicketStatus: any = createAsyncThunk(
        'editInvestorTicketStatus', async (thunkApi: any, { getState, dispatch }: any) => {
            try {
                const response: any = await axiosInstance(`${urls.base_url}onboarding/investors/ticket`, {
                    method: 'PUT',
                    data: thunkApi.data,
                });
                response.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
                response.status === 200 && dispatch(getInvestorTicketDetails(thunkApi.data.get("ticket_id")))
                response.status === 200 && dispatch(getInvestorButtonDropdown(thunkApi.data.get("ticket_id")))
                if (response.status === 200 && thunkApi.toggle !== undefined) {
                    thunkApi.toggle();
                }
                return response.data;
            }
            catch (error: any) {
                dispatch(setAlertFunc({ type: 'error', msg: error.message }))
                return thunkApi.rejectWithValue({ error: error.message });
            }
        });
        export const deleteAdmin: any = createAsyncThunk(
            'deleteAdmin', async (thunkApi: any, { getState, dispatch }) => {
                const response: any = await axiosInstance(`onboarding/investors/admin_api?ticket_id=${thunkApi?.ticket_id}&user_id=${thunkApi?.user_id}&deleted_ids=${thunkApi?.deleted_ids}`, {
                    method: 'DELETE',
                }).then(
                    (data: any) => {
                        response.status === 200 && dispatch(getInvestorTicketDetails(thunkApi.data.get("ticket_id")))
                        data.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: data?.data?.message }))
                        return data
                    }
                ).catch((error: any) => {
                    dispatch(setAlertFunc({ type: 'error', msg: error.message }))
                    return thunkApi.rejectWithValue({ error: error.message });
                })
                return response
            });

/*  */
export const getStationsTabs: any = createAsyncThunk(
    'getStationsTabs', async (obj: any, thunkApi) => {
        try {
            const response: any = await axiosInstance(`onboarding/stations/ticket_total`, {
                method: 'GET',
                params: {
                    'state_id': JSON.stringify(obj.data.stateId ?? []),
                    'status_ids': JSON.stringify(obj.data.statusId ?? []),
                    "city_id": JSON.stringify(obj.data.cityId ?? []),
                    'assigned_to': obj.data.assigned_to?.id ?? '',
                    'age': JSON.stringify(obj.data.age ?? []),
                    'age_op': obj.data.age_op ?? '',
                    'raised_on_end': obj.data.TicketEndDate ?? '',
                    'raised_on_start': obj.data.TicketStartDate ?? '',
                    'charger_type': JSON.stringify(obj.data.chargerIds ?? []),
                    'read_filter': obj.data.ReadUnread ?? 'all',
                    'search_key': obj.data.search_key ?? '',
                    'per_page': obj?.selectedValue,
                    'oem_id': obj.data.oemId?.id ?? '',
                    'connector_type': obj?.data?.connectorType?.title || '',
                    'page': obj?.page
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const getStationsData: any = createAsyncThunk(
    'getStationsData', async (obj: any, { thunkApi, dispatch }: any) => {
        try {
            const response: any = await axiosInstance(`onboarding/stations/ticket/${obj.data.parent_status ?? 'all_tab'}`, {
                method: 'GET',
                params: {
                    'state_id': JSON.stringify(obj.data.stateId ?? []),
                    'status_ids': JSON.stringify(obj.data.statusId ?? []),
                    'oem_id': obj.data.oemId?.id ?? '',
                    "city_id": JSON.stringify(obj.data.cityId ?? []),
                    'assigned_to': obj.data.assigned_to?.id ?? '',
                    'age': JSON.stringify(obj.data.age ?? []),
                    'age_op': obj.data.age_op ?? '',
                    'raised_on_end': obj.data.TicketEndDate ?? '',
                    'read_filter': obj.data.ReadUnread ?? 'all',
                    'raised_on_start': obj.data.TicketStartDate ?? '',
                    'charger_type': JSON.stringify(obj.data.chargerIds ?? []),
                    'search_key': obj.data.search_key ?? '',
                    'per_page': obj?.selectedValue,
                    'page': obj?.page,
                    'connector_type': obj?.data?.connectorType?.title || ''
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });

export const getStationsButtonDropdown: any = createAsyncThunk(
    'getStationsButtonDropdown', async (ticket_id: any, thunkApi) => {
        try {
            const response: any = await axiosInstance(`onboarding/stations/button`, {
                method: 'GET',
                params: {
                    "ticket_id": ticket_id ?? '',
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });

export const getHostTabs: any = createAsyncThunk(
    'getHostTabs', async (obj: any, thunkApi) => {
        try {
            const response: any = await axiosInstance(`onboarding/host/total`, {
                method: 'GET',
                params: {
                    'state_id': JSON.stringify(obj.data.stateId ?? []),
                    'status_ids': JSON.stringify(obj.data.statusId ?? []),
                    "city_id": JSON.stringify(obj.data.cityId ?? []),
                    'assigned_to': obj.data.assigned_to?.id ?? '',
                    'age': JSON.stringify(obj.data.age ?? []),
                    'age_op': obj.data.age_op ?? '',
                    'raised_on_end': obj.data.TicketEndDate ?? '',
                    'raised_on_start': obj.data.TicketStartDate ?? '',
                    'charger_type': JSON.stringify(obj.data.chargerIds ?? []),
                    'read_filter': obj.data.ReadUnread ?? 'all',
                    'search_key': obj.data.search_key ?? '',
                    'per_page': obj?.selectedValue,
                    'cpo_name': JSON.stringify(obj.data.cpoId?.id ?? ''),
                    'oem_id': obj.data.oemId?.id ?? '',
                    'page': obj?.page
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const getHostData: any = createAsyncThunk(
    'getHostData', async (obj: any, { thunkApi, dispatch }: any) => {
        try {
            const response: any = await axiosInstance(`onboarding/host/ticket/${obj.data.parent_status ?? 'all_tab'}`, {
                method: 'GET',
                params: {
                    'state_id': JSON.stringify(obj.data.stateId ?? []),
                    'status_ids': JSON.stringify(obj.data.statusId ?? []),
                    'oem_id': obj.data.oemId?.id ?? '',
                    "city_id": JSON.stringify(obj.data.cityId ?? []),
                    'assigned_to': obj.data.assigned_to?.id ?? '',
                    'age': JSON.stringify(obj.data.age ?? []),
                    'age_op': obj.data.age_op ?? '',
                    'raised_on_end': obj.data.TicketEndDate ?? '',
                    'read_filter': obj.data.ReadUnread ?? 'all',
                    'raised_on_start': obj.data.TicketStartDate ?? '',
                    'charger_type': JSON.stringify(obj.data.chargerIds ?? []),
                    'cpo_name': JSON.stringify(obj.data.cpoId?.id ?? ''),
                    'search_key': obj.data.search_key ?? '',
                    'per_page': obj?.selectedValue,
                    'page': obj?.page
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });

export const getHostButtonDropdown: any = createAsyncThunk(
    'getHostButtonDropdown', async (ticket_id: any, thunkApi) => {
        try {
            const response: any = await axiosInstance(`onboarding/host/button`, {
                method: 'GET',
                params: {
                    "ticket_id": ticket_id ?? '',
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });

/*  */
export const getSkipStatuses: any = createAsyncThunk(
    'getStatuses', async (obj: any, thunkApi) => {
        try {
            const response: any = await axiosInstance(`onboarding/cpo/statuses`, {
                method: 'GET',
                params: {
                    "search_key": obj.search_key ?? '',
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });

export const getCPOTicketHistory: any = createAsyncThunk(
    'getCPOTicketHistory', async (ticket_id: any, thunkApi: any) => {
        try {
            const response: any = await axiosInstance(`onboarding/cpo/history`, {
                method: 'GET',
                params: {
                    "ticket_id": ticket_id ?? '',
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const getCPOTicketDetails: any = createAsyncThunk(
    'getCPOTicketDetails', async (ticket_id: any, thunkApi: any) => {
        try {
            const response: any = await axiosInstance(`onboarding/cpo/ticket_details`, {
                method: 'GET',
                params: {
                    "ticket_id": ticket_id ?? '',
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
/*  */
export const getStationsTicketHistory: any = createAsyncThunk(
    'getStationsTicketHistory', async (ticket_id: any, thunkApi: any) => {
        try {
            const response: any = await axiosInstance(`onboarding/stations/history`, {
                method: 'GET',
                params: {
                    "ticket_id": ticket_id ?? '',
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const getStationsTicketDetails: any = createAsyncThunk(
    'getStationsTicketDetails', async (ticket_id: any, thunkApi: any) => {
        try {
            const response: any = await axiosInstance(`onboarding/stations/ticket_details`, {
                method: 'GET',
                params: {
                    "ticket_id": ticket_id ?? '',
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });

export const getHostSkipStatuses: any = createAsyncThunk(
    'getHostSkipStatuses', async (obj: any, thunkApi) => {
        try {
            const response: any = await axiosInstance(`onboarding/host/statuses`, {
                method: 'GET',
                params: {
                    "search_key": obj.search_key ?? '',
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });

export const getHostTicketHistory: any = createAsyncThunk(
    'getHostTicketHistory', async (ticket_id: any, thunkApi: any) => {
        try {
            const response: any = await axiosInstance(`onboarding/host/history`, {
                method: 'GET',
                params: {
                    "ticket_id": ticket_id ?? '',
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const getHostTicketDetails: any = createAsyncThunk(
    'getHostTicketDetails', async (ticket_id: any, thunkApi: any) => {
        try {
            const response: any = await axiosInstance(`onboarding/host/ticket_details`, {
                method: 'GET',
                params: {
                    "ticket_id": ticket_id ?? '',
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });

/*  */
export const addAdmin: any = createAsyncThunk(
    'addAdmin',
    async (thunkAPI: any, { getState, dispatch }: any) => {
        try {
            const response: any = await axiosInstance(`${urls.base_url}onboarding/cpo/ticket_details`, {
                method: 'POST',
                data: thunkAPI.data,
            })
            response.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            response.status === 200 && dispatch(getCPOTicketDetails(thunkAPI.data.get("ticket_id")))
            if (response.status === 200 && thunkAPI.toggle !== undefined) {
                thunkAPI.toggle();
            }
            return response.data
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);
export const editTicketStatus: any = createAsyncThunk(
    'editTicketStatus', async (thunkApi: any, { getState, dispatch }: any) => {
        try {
            const response: any = await axiosInstance(`${urls.base_url}onboarding/cpo/ticket`, {
                method: 'PUT',
                data: thunkApi.data,
            });
            response.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            response.status === 200 && dispatch(getCPOTicketDetails(thunkApi.data.get("ticket_id")))
            response.status === 200 && dispatch(getCPOButtonDropdown(thunkApi.data.get("ticket_id")))
            if (response.status === 200 && thunkApi.toggle !== undefined) {
                thunkApi.toggle();
            }
            return response.data;
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });

export const addHostAdmin: any = createAsyncThunk(
    'addHostAdmin',
    async (thunkAPI: any, { getState, dispatch }: any) => {
        try {
            const response: any = await axiosInstance(`${urls.base_url}onboarding/host/ticket_details`, {
                method: 'POST',
                data: thunkAPI.data,
            })
            response.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            response.status === 200 && dispatch(getHostTicketDetails(thunkAPI.data.get("ticket_id")))
            if (response.status === 200 && thunkAPI.toggle !== undefined) {
                thunkAPI.toggle();
            }
            return response.data
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);
export const editHostTicketStatus: any = createAsyncThunk(
    'editHostTicketStatus', async (thunkApi: any, { getState, dispatch }: any) => {
        try {
            const response: any = await axiosInstance(`${urls.base_url}onboarding/host/ticket`, {
                method: 'PUT',
                data: thunkApi.data,
            });
            response.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            response.status === 200 && dispatch(getHostTicketDetails(thunkApi.data.get("ticket_id")))
            response.status === 200 && dispatch(getHostButtonDropdown(thunkApi.data.get("ticket_id")))
            if (response.status === 200 && thunkApi.toggle !== undefined) {
                thunkApi.toggle();
            }
            return response.data;
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });


export const editTicketStatusStations: any = createAsyncThunk(
    'editTicketStatusStations', async (thunkApi: any, { getState, dispatch }: any) => {
        try {
            const response: any = await axiosInstance(`${urls.base_url}onboarding/stations/ticket`, {
                method: 'PUT',
                data: thunkApi.data,
            });
            response.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            response.status === 200 && dispatch(getStationsTicketDetails(thunkApi.data.get("ticket_id")))
            response.status === 200 && dispatch(getStationsButtonDropdown(thunkApi.data.get("ticket_id")))
            if (response.status === 200 && thunkApi.toggle !== undefined) {
                thunkApi.toggle();
            }
            return response.data;
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const editTicketDetail: any = createAsyncThunk(
    'editTicketDetail', async (thunkApi: any, { getState, dispatch }: any) => {
        try {
            const response: any = await axiosInstance(`${urls.base_url}onboarding/cpo/ticket_details`, {
                method: 'PUT',
                data: JSON.stringify(thunkApi)
            });
            response.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            response.status === 200 && dispatch(getCPOTicketDetails(thunkApi.ticket_id))
            response.status === 200 && dispatch(getCPOButtonDropdown(thunkApi.ticket_id))
            if (response.status === 200 && thunkApi.toggle !== undefined) {
                thunkApi.toggle();
            }
            return response.data;
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const editHostTicketDetail: any = createAsyncThunk(
    'editHostTicketDetail', async (thunkApi: any, { getState, dispatch }: any) => {
        try {
            const response: any = await axiosInstance(`${urls.base_url}onboarding/host/ticket_details`, {
                method: 'PUT',
                data: thunkApi.data
            });
            response.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            response.status === 200 && dispatch(getHostTicketDetails(thunkApi.data.get("ticket_id")))
            response.status === 200 && dispatch(getHostButtonDropdown(thunkApi.data.get("ticket_id")))
            if (response.status === 200 && thunkApi.toggle !== undefined) {
                thunkApi.toggle();
            }
            return response.data;
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });

const OnboardingSlice = createSlice({
    name: 'Onboarding',
    initialState,
    reducers: {
        resetState: (state: any, payload: any) => {
            state.addSuccess = false
            state.totalPages = 1
            state.page = 1
            state.selectedValue = 10
            state.CPOFormValue = {
                cityId: [],
                stateId: [],
                chargerIds: [],
                statusId: [],
                age_op: '',
                age: [],
                oemId: '',
                more_than_age: '',
                between_age1: '',
                between_age2: '',
                less_than_age: '',
                assigned_to: '',
                TicketStartDate: '',
                TicketEndDate: '',
                search_key: '',
                sow: '',
                parent_status: 'all_tab'
            }
            state.CPOTabsLoading = false
            state.CPOTabsData = []
            state.CPOLoading = false
            state.CPOData = []
            /*  */
            state.StationsFormValue = {
                cityId: [],
                stateId: [],
                chargerIds: [],
                statusId: [],
                age_op: '',
                age: [],
                oemId: '',
                more_than_age: '',
                between_age1: '',
                between_age2: '',
                less_than_age: '',
                assigned_to: '',
                TicketStartDate: '',
                TicketEndDate: '',
                search_key: '',
                sow: '',
                parent_status: 'all_tab'
            }
            state.StationsTabsLoading = false
            state.StationsTabsData = []
            state.StationsLoading = false
            state.StationsData = []

            /* */
            state.HostFormValue = {
                cityId: [],
                stateId: [],
                chargerIds: [],
                statusId: [],
                age_op: '',
                age: [],
                oemId: '',
                more_than_age: '',
                between_age1: '',
                between_age2: '',
                less_than_age: '',
                assigned_to: '',
                TicketStartDate: '',
                TicketEndDate: '',
                search_key: '',
                sow: '',
                cpoId: '',
                parent_status: 'all_tab'
            }
            state.HostTabsLoading = false
            state.HostTabsData = []
            state.HostLoading = false
            state.HostData = []
            /* */

            state.chargerDisplayValues = []
            state.chargerCurrentRules = []
            state.StateDisplayValues = []
            state.StateCurrentRules = []
            state.CityDisplayValues = []
            state.CityCurrentRules = []
            state.currentRules = []
            state.displayValues = []
            state.CPOButtonLoading = false
            state.CPOButtonData = []
            state.CPOHistoryData = []
            state.CPOHistoryLoading = false
            state.CPODetailLoading = false
            state.CPODealDetailData = []
            /*  */
            state.StationsButtonLoading = false
            state.StationsButtonData = []
            state.StationsHistoryData = []
            state.StationsHistoryLoading = false
            state.StationsDetailLoading = false
            state.StationsDealDetailData = []
            /*  */
            state.HostButtonLoading = false
            state.HostButtonData = []
            state.HostHistoryData = []
            state.HostHistoryLoading = false
            state.HostDetailLoading = false
            state.HostDealDetailData = []
            /* */
            state.addAdminSuccess = false
            state.addAdminLoading = false
            state.editTicketStatusSuccess = false
            state.editTicketStatusLoading = false
            state.skipStatusLoading = false
            state.skipStatusData = []
            state.editTicketDetailsSuccess = false
            state.editTicketDetailsLoading = false
        },
        setCPOForm: (state: any, payload: any) => {
            state.CPOFormValue = payload?.payload
        },
        setInvestorForm: (state: any, payload: any) => {
            state.InvestorFormValue = payload?.payload
        },
        setAddInvestorForm: (state: any, payload: any) => {
            state.addInvestorFormValue = payload?.payload
        },
        setEditInvestorForm: (state: any, payload: any) => {
            state.editInvestorFormValue = payload?.payload
        },
        setStationsForm: (state: any, payload: any) => {
            state.StationsFormValue = payload?.payload
        },
        setHostForm: (state: any, payload: any) => {
            state.HostFormValue = payload?.payload
        },
        setDisplayValues: (state: any, payload: any) => {
            state.displayValues = payload?.payload
        },
        setCurrentRuleValue: (state: any, payload: any) => {
            state.currentRules = payload?.payload
        },
        setChargerDisplayValues: (state: any, payload: any) => {
            state.chargerDisplayValues = payload?.payload
        },
        setChargerCurrentRuleValue: (state: any, payload: any) => {
            state.chargerCurrentRules = payload?.payload
        },
        setCityDisplayValues: (state: any, payload: any) => {
            state.CityDisplayValues = payload?.payload
        },
        setCityCurrentRuleValue: (state: any, payload: any) => {
            state.CityCurrentRules = payload?.payload
        },
        setStateDisplayValues: (state: any, payload: any) => {
            state.StateDisplayValues = payload?.payload
        },
        setInvestorChargerValues: (state: any, payload: any) => {
            state.InvestorChargers = payload?.payload
        },
        setAddedByDisplayValues: (state: any, payload: any) => {
            state.addedByDisplayValues = payload?.payload
        },
        setAddedByCurrentRuleValue: (state: any, payload: any) => {
            state.addedByCurrentRules = payload?.payload
        },
        setStateCurrentRuleValue: (state: any, payload: any) => {
            state.StateCurrentRules = payload?.payload
        },
        setPages: (state: any, payload: any) => {
            state.page = payload?.payload
        },
        setHostPages: (state: any, payload: any) => {
            state.HostPage = payload?.payload
        },
        setCPOPages: (state: any, payload: any) => {
            state.CpoPage = payload?.payload
        },
        setSelectedValues: (state: any, payload: any) => {
            state.selectedValue = payload?.payload
        },
        setCPODealTab: (state: any, payload: any) => {
            state.CPODealTab = payload?.payload
        },
        setCPOSelectedTab: (state: any, payload: any) => {
            state.CPOselectedTab = payload?.payload
        },
        setInvestorPages: (state: any, payload: any) => {
            state.InvestorPage = payload?.payload
        },
        setInvestorDealTab: (state: any, payload: any) => {
            state.InvestorDealTab = payload?.payload
        },
        setInvestorSelectedTab: (state: any, payload: any) => {
            state.InvestorselectedTab = payload?.payload
        },
        setHostDealTab: (state: any, payload: any) => {
            state.HostDealTab = payload?.payload
        },
        setHostSelectedTab: (state: any, payload: any) => {
            state.HostselectedTab = payload?.payload
        },
        setStationDealTab: (state: any, payload: any) => {
            state.StationDealTab = payload?.payload
        },
        setStationSelectedTab: (state: any, payload: any) => {
            state.StationselectedTab = payload?.payload
        },

    },
    extraReducers: (builder: any) => {
        return builder.addCase(getCPOTabs.pending, (state: any) => {
            state.CPOTabsLoading = true
        }),
            builder.addCase(getCPOTabs.fulfilled, (state: any, { payload }: any) => {
                state.CPOTabsLoading = false
                state.CPOTabsData = payload.data.message
            }),
            builder.addCase(getCPOTabs.rejected, (state: any) => {
                state.CPOTabsLoading = false
                state.CPOTabsData = []
                state.DealDetailsData = []
            }),
            builder.addCase(getCPOData.pending, (state: any) => {
                state.CPOLoading = true
                state.totalPages = 1
            }),
            builder.addCase(getCPOData.fulfilled, (state: any, { payload }: any) => {
                state.CPOLoading = false
                state.CPOData = payload
                state.addOpsCommentSuccess = false
                state.editOpsCommentSuccess = false
                state.deleteOpsCommentSuccess = false
                state.totalPages = payload?.totalPages
            }),
            builder.addCase(getCPOData.rejected, (state: any) => {
                state.CPOLoading = false
                state.CPOData = []
                state.totalPages = 0
            }),
            builder.addCase(getCPOButtonDropdown.pending, (state: any) => {
                state.CPOButtonLoading = true
            }),
            builder.addCase(getCPOButtonDropdown.fulfilled, (state: any, { payload }: any) => {
                state.CPOButtonLoading = false
                state.CPOButtonData = payload?.data
            }),
            builder.addCase(getCPOButtonDropdown.rejected, (state: any) => {
                state.CPOButtonLoading = false
                state.CPOButtonData = []
            }),
            /* */
            builder.addCase(getAddedBy.pending, (state: any) => {
                state.addedByLoading = true
            }),
            builder.addCase(getAddedBy.fulfilled, (state: any, { payload }: any) => {
                state.addedByLoading = false
                state.addedByData = payload?.data
            }),
            builder.addCase(getAddedBy.rejected, (state: any) => {
                state.addedByLoading = false
                state.addedByData = []
            }),
            builder.addCase(getInvestorTabs.pending, (state: any) => {
                state.InvestorTabsLoading = true
            }),
            builder.addCase(getInvestorTabs.fulfilled, (state: any, { payload }: any) => {
                state.InvestorTabsLoading = false
                state.InvestorTabsData = payload.data.message
            }),
            builder.addCase(getInvestorTabs.rejected, (state: any) => {
                state.InvestorTabsLoading = false
                state.InvestorTabsData = []
                state.DealDetailsData = []
            }),
            builder.addCase(getInvestorData.pending, (state: any) => {
                state.InvestorLoading = true
                state.totalPages = 1
            }),
            builder.addCase(getInvestorData.fulfilled, (state: any, { payload }: any) => {
                state.InvestorLoading = false
                state.InvestorData = payload
                state.addOpsCommentSuccess = false
                state.editOpsCommentSuccess = false
                state.deleteOpsCommentSuccess = false
                state.totalPages = payload?.totalPages
            }),
            builder.addCase(getInvestorData.rejected, (state: any) => {
                state.InvestorLoading = false
                state.InvestorData = []
                state.totalPages = 0
            }),
            builder.addCase(getInvestorButtonDropdown.pending, (state: any) => {
                state.InvestorButtonLoading = true
            }),
            builder.addCase(getInvestorButtonDropdown.fulfilled, (state: any, { payload }: any) => {
                state.InvestorButtonLoading = false
                state.InvestorButtonData = payload?.data
            }),
            builder.addCase(getInvestorButtonDropdown.rejected, (state: any) => {
                state.InvestorButtonLoading = false
                state.InvestorButtonData = []
            }),
            builder.addCase(getInvestorProfit.pending, (state: any) => {
                state.InvestorProfitLoading = true
            }),
            builder.addCase(getInvestorProfit.fulfilled, (state: any, { payload }: any) => {
                state.InvestorProfitLoading = false
                state.InvestorProfitData = payload?.data
            }),
            builder.addCase(getInvestorProfit.rejected, (state: any) => {
                state.InvestorProfitLoading = false
                state.InvestorProfitData = []
            }),
            builder.addCase(addInvestor.pending, (state: any) => {
                state.addInvestorLoading = true
                state.addInvestorSuccess = false
            }),
            builder.addCase(addInvestor.fulfilled, (state: any) => {
                state.addInvestorLoading = false
                state.addInvestorSuccess = true
            }),
            builder.addCase(addInvestor.rejected, (state: any) => {
                state.addInvestorLoading = false
                state.addInvestorSuccess = false
            }),
            builder.addCase(getInvestorTicketDetails.pending, (state: any) => {
                state.InvestorDetailLoading = true
            }),
            builder.addCase(getInvestorTicketDetails.fulfilled, (state: any, { payload }: any) => {
                state.InvestorDetailLoading = false
                state.editTicketStatusSuccess = false
                state.addInvestorAdminSuccess = false
                state.editTicketDetailsSuccess = false
                state.InvestorDealDetailData = payload
            }),
            builder.addCase(getInvestorTicketDetails.rejected, (state: any) => {
                state.InvestorDetailLoading = false
                state.InvestorDealDetailData = []
            }),
            builder.addCase(addInvestorAdmin.pending, (state: any) => {
                state.addInvestorAdminLoading = true
                state.addInvestorAdminSuccess = false
            }),
            builder.addCase(addInvestorAdmin.fulfilled, (state: any) => {
                state.addInvestorAdminLoading = false
                state.addInvestorAdminSuccess = true
            }),
            builder.addCase(addInvestorAdmin.rejected, (state: any) => {
                state.addInvestorAdminLoading = false
                state.addInvestorAdminSuccess = false
            }),
            builder.addCase(getInvestorTicketHistory.pending, (state: any) => {
                state.InvestorHistoryLoading = true
            }),
            builder.addCase(getInvestorTicketHistory.fulfilled, (state: any, { payload }: any) => {
                state.InvestorHistoryLoading = false
                state.InvestorHistoryData = payload
            }),
            builder.addCase(getInvestorTicketHistory.rejected, (state: any) => {
                state.InvestorHistoryLoading = false
                state.InvestorHistoryData = []
            }),
            builder.addCase(editInvestor.pending, (state: any) => {
                state.editInvestorLoading = true
                state.editInvestorSuccess = false
            }),
            builder.addCase(editInvestor.fulfilled, (state: any) => {
                state.editInvestorLoading = false
                state.editInvestorSuccess = true
            }),
            builder.addCase(editInvestor.rejected, (state: any) => {
                state.editInvestorSuccess = false
            }),
            builder.addCase(editInvestorTicketStatus.pending, (state: any) => {
                state.editInvestorTicketStatusLoading = true
                state.editInvestorTicketStatusSuccess = false
            }),
            builder.addCase(editInvestorTicketStatus.fulfilled, (state: any) => {
                state.editInvestorTicketStatusLoading = false
                state.editInvestorTicketStatusSuccess = true
            }),
            builder.addCase(editInvestorTicketStatus.rejected, (state: any) => {
                state.editInvestorTicketStatusSuccess = false
            }),
            builder.addCase(deleteAdmin.pending, (state: any) => {
                state.deleteAdminLoading = true
                state.deleteAdminSuccess = false
            }),
            builder.addCase(deleteAdmin.fulfilled, (state: any, { payload }: any) => {
                state.deleteAdminLoading = false
                state.deleteAdminSuccess = true
            }),
            builder.addCase(deleteAdmin.rejected, (state: any) => {
                state.deleteAdminLoading = false
                state.deleteAdminSuccess = false
            }),
            /*  */
            builder.addCase(getStationsTabs.pending, (state: any) => {
                state.StationsTabsLoading = true
            }),
            builder.addCase(getStationsTabs.fulfilled, (state: any, { payload }: any) => {
                state.StationsTabsLoading = false
                state.StationsTabsData = payload.data.message
            }),
            builder.addCase(getStationsTabs.rejected, (state: any) => {
                state.StationsTabsLoading = false
                state.StationsTabsData = []
                state.DealDetailsData = []
            }),
            builder.addCase(getStationsData.pending, (state: any) => {
                state.StationsLoading = true
                state.totalPages = 1
            }),
            builder.addCase(getStationsData.fulfilled, (state: any, { payload }: any) => {
                state.StationsLoading = false
                state.StationsData = payload
                state.addOpsCommentSuccess = false
                state.editOpsCommentSuccess = false
                state.deleteOpsCommentSuccess = false
                state.totalPages = payload?.totalPages
            }),
            builder.addCase(getStationsData.rejected, (state: any) => {
                state.StationsLoading = false
                state.StationsData = []
                state.totalPages = 0
            }),
            builder.addCase(getStationsButtonDropdown.pending, (state: any) => {
                state.StationsButtonLoading = true
            }),
            builder.addCase(getStationsButtonDropdown.fulfilled, (state: any, { payload }: any) => {
                state.StationsButtonLoading = false
                state.StationsButtonData = payload?.data
            }),
            builder.addCase(getStationsButtonDropdown.rejected, (state: any) => {
                state.StationsButtonLoading = false
                state.StationsButtonData = []
            }),
            /* */
            builder.addCase(getHostTabs.pending, (state: any) => {
                state.HostTabsLoading = true
            }),
            builder.addCase(getHostTabs.fulfilled, (state: any, { payload }: any) => {
                state.HostTabsLoading = false
                state.HostTabsData = payload.data.message
            }),
            builder.addCase(getHostTabs.rejected, (state: any) => {
                state.HostTabsLoading = false
                state.HostTabsData = []
                state.DealDetailsData = []
            }),
            builder.addCase(getHostData.pending, (state: any) => {
                state.HostLoading = true
                state.totalPages = 1
            }),
            builder.addCase(getHostData.fulfilled, (state: any, { payload }: any) => {
                state.HostLoading = false
                state.HostData = payload
                state.addOpsCommentSuccess = false
                state.editOpsCommentSuccess = false
                state.deleteOpsCommentSuccess = false
                state.totalPages = payload?.totalPages
            }),
            builder.addCase(getHostData.rejected, (state: any) => {
                state.HostLoading = false
                state.HostData = []
                state.totalPages = 0
            }),
            builder.addCase(getHostButtonDropdown.pending, (state: any) => {
                state.HostButtonLoading = true
            }),
            builder.addCase(getHostButtonDropdown.fulfilled, (state: any, { payload }: any) => {
                state.HostButtonLoading = false
                state.HostButtonData = payload?.data
            }),
            builder.addCase(getHostButtonDropdown.rejected, (state: any) => {
                state.HostButtonLoading = false
                state.HostButtonData = []
            }),
            /*  */
            builder.addCase(getSkipStatuses.pending, (state: any) => {
                state.skipStatusLoading = true
            }),
            builder.addCase(getSkipStatuses.fulfilled, (state: any, { payload }: any) => {
                state.skipStatusLoading = false
                state.skipStatusData = payload
            }),
            builder.addCase(getSkipStatuses.rejected, (state: any) => {
                state.skipStatusLoading = false
                state.skipStatusData = []
            }),
            /* */
            builder.addCase(getHostSkipStatuses.pending, (state: any) => {
                state.skipStatusLoading = true
            }),
            builder.addCase(getHostSkipStatuses.fulfilled, (state: any, { payload }: any) => {
                state.skipStatusLoading = false
                state.skipStatusData = payload
            }),
            builder.addCase(getHostSkipStatuses.rejected, (state: any) => {
                state.skipStatusLoading = false
                state.skipStatusData = []
            }),
            /* */
            builder.addCase(getCPOTicketHistory.pending, (state: any) => {
                state.CPOHistoryLoading = true
            }),
            builder.addCase(getCPOTicketHistory.fulfilled, (state: any, { payload }: any) => {
                state.CPOHistoryLoading = false
                state.CPOHistoryData = payload
            }),
            builder.addCase(getCPOTicketHistory.rejected, (state: any) => {
                state.CPOHistoryLoading = false
                state.CPOHistoryData = []
            }),
            builder.addCase(getCPOTicketDetails.pending, (state: any) => {
                state.CPODetailLoading = true
                // state.editTicketStatusSuccess = false
            }),
            builder.addCase(getCPOTicketDetails.fulfilled, (state: any, { payload }: any) => {
                state.CPODetailLoading = false
                state.editTicketStatusSuccess = false
                state.addAdminSuccess = false
                state.editTicketDetailsSuccess = false
                state.CPODealDetailData = payload
            }),
            builder.addCase(getCPOTicketDetails.rejected, (state: any) => {
                state.CPODetailLoading = false
                state.CPODealDetailData = []
            }),
            /*  */
            builder.addCase(getStationsTicketHistory.pending, (state: any) => {
                state.StationsHistoryLoading = true
            }),
            builder.addCase(getStationsTicketHistory.fulfilled, (state: any, { payload }: any) => {
                state.StationsHistoryLoading = false
                state.StationsHistoryData = payload
            }),
            builder.addCase(getStationsTicketHistory.rejected, (state: any) => {
                state.StationsHistoryLoading = false
                state.StationsHistoryData = []
            }),
            builder.addCase(getStationsTicketDetails.pending, (state: any) => {
                state.StationsDetailLoading = true
                // state.editTicketStatusSuccess = false
            }),
            builder.addCase(getStationsTicketDetails.fulfilled, (state: any, { payload }: any) => {
                state.StationsDetailLoading = false
                state.editTicketStatusSuccess = false
                state.addAdminSuccess = false
                state.editTicketDetailsSuccess = false
                state.StationsDealDetailData = payload
            }),
            builder.addCase(getStationsTicketDetails.rejected, (state: any) => {
                state.StationsDetailLoading = false
                state.StationsDealDetailData = []
            }),
            /*  */
            builder.addCase(getHostTicketHistory.pending, (state: any) => {
                state.HostHistoryLoading = true
            }),
            builder.addCase(getHostTicketHistory.fulfilled, (state: any, { payload }: any) => {
                state.HostHistoryLoading = false
                state.HostHistoryData = payload
            }),
            builder.addCase(getHostTicketHistory.rejected, (state: any) => {
                state.HostHistoryLoading = false
                state.HostHistoryData = []
            }),
            builder.addCase(getHostTicketDetails.pending, (state: any) => {
                state.HostDetailLoading = true
                // state.editTicketStatusSuccess = false
            }),
            builder.addCase(getHostTicketDetails.fulfilled, (state: any, { payload }: any) => {
                state.HostDetailLoading = false
                state.editTicketStatusSuccess = false
                state.addAdminSuccess = false
                state.editTicketDetailsSuccess = false
                state.HostDealDetailData = payload
            }),
            builder.addCase(getHostTicketDetails.rejected, (state: any) => {
                state.HostDetailLoading = false
                state.HostDealDetailData = []
            }),
            /*  */
            builder.addCase(addAdmin.pending, (state: any) => {
                state.addAdminLoading = true
                state.addAdminSuccess = false
            }),
            builder.addCase(addAdmin.fulfilled, (state: any) => {
                state.addAdminLoading = false
                state.addAdminSuccess = true
            }),
            builder.addCase(addAdmin.rejected, (state: any) => {
                state.addAdminLoading = false
                state.addAdminSuccess = false
            }),
            builder.addCase(editTicketStatus.pending, (state: any) => {
                state.editTicketStatusLoading = true
                state.editTicketStatusSuccess = false
            }),
            builder.addCase(editTicketStatus.fulfilled, (state: any) => {
                state.editTicketStatusLoading = false
                state.editTicketStatusSuccess = true
            }),
            builder.addCase(editTicketStatus.rejected, (state: any) => {
                state.editTicketStatusSuccess = false
            }),
            /*  */
            builder.addCase(addHostAdmin.pending, (state: any) => {
                state.addAdminLoading = true
                state.addAdminSuccess = false
            }),
            builder.addCase(addHostAdmin.fulfilled, (state: any) => {
                state.addAdminLoading = false
                state.addAdminSuccess = true
            }),
            builder.addCase(addHostAdmin.rejected, (state: any) => {
                state.addAdminLoading = false
                state.addAdminSuccess = false
            }),
            builder.addCase(editHostTicketStatus.pending, (state: any) => {
                state.editTicketStatusLoading = true
                state.editTicketStatusSuccess = false
            }),
            builder.addCase(editHostTicketStatus.fulfilled, (state: any) => {
                state.editTicketStatusLoading = false
                state.editTicketStatusSuccess = true
            }),
            builder.addCase(editHostTicketStatus.rejected, (state: any) => {
                state.editTicketStatusSuccess = false
            }),
            /* */
            builder.addCase(editTicketStatusStations.pending, (state: any) => {
                state.editTicketStatusLoading = true
                state.editTicketStatusSuccess = false
            }),
            builder.addCase(editTicketStatusStations.fulfilled, (state: any) => {
                state.editTicketStatusLoading = false
                state.editTicketStatusSuccess = true
            }),
            builder.addCase(editTicketStatusStations.rejected, (state: any) => {
                state.editTicketStatusSuccess = false
            }),
            /*  */
            builder.addCase(editTicketDetail.pending, (state: any) => {
                state.editTicketDetailsLoading = true
                state.editTicketDetailsSuccess = false
            }),
            builder.addCase(editTicketDetail.fulfilled, (state: any) => {
                state.editTicketDetailsLoading = false
                state.editTicketDetailsSuccess = true
            }),
            builder.addCase(editTicketDetail.rejected, (state: any) => {
                state.editTicketDetailsSuccess = false
            }),
            /* */
            builder.addCase(editHostTicketDetail.pending, (state: any) => {
                state.editTicketDetailsLoading = true
                state.editTicketDetailsSuccess = false
            }),
            builder.addCase(editHostTicketDetail.fulfilled, (state: any) => {
                state.editTicketDetailsLoading = false
                state.editTicketDetailsSuccess = true
            }),
            builder.addCase(editHostTicketDetail.rejected, (state: any) => {
                state.editTicketDetailsSuccess = false
            })
    }
})

export const { setDisplayValues, setChargerCurrentRuleValue, setChargerDisplayValues, setAddedByCurrentRuleValue, setAddedByDisplayValues, setCurrentRuleValue, setCPOForm, setStationsForm, setInvestorDealTab, setInvestorSelectedTab, setInvestorPages,
    setCityCurrentRuleValue, setCityDisplayValues, setStateCurrentRuleValue, setStateDisplayValues, setPages, setSelectedValues, setCPOPages, setHostPages, setInvestorForm, setAddInvestorForm,
    setCPOSelectedTab, setCPODealTab, setHostSelectedTab, setHostDealTab, setStationDealTab, setStationSelectedTab, setHostForm, resetState, setInvestorChargerValues, setEditInvestorForm } = OnboardingSlice.actions
export const getAllUser = (state: any) => state.users.loading
export const addUserSuccess = (state: any) => state.users.addSuccess

export default OnboardingSlice.reducer
