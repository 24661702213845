import { api } from "../../api"
import { getTagType } from "../../utils/utils"
import { ChargerDetailsData, IResponse, TagTypes } from "./commonTypes"

const commonService = api.injectEndpoints({
    endpoints: build => ({
        getChargerDetails: build.query<ChargerDetailsData[], { ticket_id: string; ticket_type: string }>({
            query: params => ({
                url: `charger_details`,
                params: {
                    ticket_id: params.ticket_id,
                    ticket_type: params.ticket_type,
                },
            }),
            transformResponse: (response: IResponse<ChargerDetailsData[]>) => response.data,
            providesTags: (result, error, arg) => [
                { type: getTagType(arg.ticket_type) as TagTypes, id: arg.ticket_id },
            ],
        }),
        updateChargerDetails: build.mutation<
            IResponse<string>,
            { data: { ticket_id: string; ticket_type: string; data: Partial<ChargerDetailsData[]> } }
        >({
            query: ({ data }) => ({
                url: `charger_details`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: (result, error, arg) => [
                { type: getTagType(arg.data.ticket_type) as TagTypes, id: arg.data.ticket_id },
            ],
        }),
    }),
})

export const { useLazyGetChargerDetailsQuery, useUpdateChargerDetailsMutation } = commonService
