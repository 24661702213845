import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { axiosInstance } from "../Auth/components/axiosInterceptors"
import { setAlertFunc } from "../../components/AlertToaster/AlertSlice"
import urls from "../../enums/urlList"

interface ThunkApiConfig {
    rejectValue: {
        error: string
    }
}
interface FinanceFormValue {
    cityId: any
    stateId: any
    chargerIds: any
    statusId: any
    age_op: string
    age: any
    more_than_age: string
    between_age1: string
    between_age2: string
    less_than_age: string
    assigned_to: Record<string, any>
    TicketStartDate: string
    TicketEndDate: string
    ETDStartDate: string
    ETDEndDate: string
    ClosureStartDate: string
    ClosureEndDate: string
    TaxationStartDate: string
    TaxationEndDate: string
    search_key: string
    sow: string
    parent_status: string
}

interface InitialState {
    users: any
    loading: boolean
    addSuccess: boolean
    totalPages: number
    page: number
    selectedValue: number
    addLoading: boolean
    editLoading: boolean
    EditSuccess: boolean
    chargerDisplayValues: any
    chargerCurrentRules: any
    StateDisplayValues: any
    StateCurrentRules: any
    CityDisplayValues: any
    CityCurrentRules: any
    currentRules: any
    displayValues: any
    FinanceFormValue: FinanceFormValue
    FinanceTabsLoading: boolean
    FinanceTabsData: any
    FinanceLoading: boolean
    FinanceData: any
    FinanceDetailLoading: boolean
    FinanceDealDetailData: any
    FinanceHistoryLoading: boolean
    FinanceHistoryData: any
    FinanceOthersLoading: boolean
    FinanceOthersData: any
    FinanceButtonLoading: boolean
    FinanceButtonData: any
    deleteTransactionLoading: boolean
    deleteTransactionSuccess: boolean
    deletePaymentSuccess: boolean
    deletePaymentLoading: boolean
    addPurchaseOrderSuccess: boolean
    addPurchaseOrderLoading: boolean
    editTicketDetailsSuccess: boolean
    editTicketDetailsLoading: boolean
    editTicketStatusSuccess: boolean
    editTicketStatusLoading: boolean
    addTaxInvoiceSuccess:boolean,
    addTaxInvoiceLoading:boolean,
    deleteTaxInvoiceSuccess: boolean,
    deleteTaxInvoiceLoading: boolean,
    editTaxInvoiceSuccess: boolean,
    editTaxInvoiceLoading: boolean,
    skipStatusLoading: boolean
    skipStatusData: any
    editSkipStatusSuccess: boolean
    editSkipStatusLoading: boolean
    ChargerTemplateLoading: boolean
    addTransactionSuccess: boolean
    addTransactionLoading: boolean
    verifyTransactionSuccess: boolean
    verifyTransactionLoading: boolean
    addPaymentTermSuccess: boolean
    addPaymentTermLoading: boolean
    ChargerTemplateData: any
    PaymentConditionLoading: boolean
    PaymentConditionData: any
    PaymentTimeLoading: boolean
    PaymentTimeData: any
    FinanceDealTab: number
    selectedTab: number
    DealDetailsData: any
    addOpsCommentSuccess: boolean
    editOpsCommentSuccess: boolean
    deleteOpsCommentSuccess: boolean
}

const initialState: InitialState = {
    users: [],
    loading: false,
    addSuccess: false,
    totalPages: 1,
    page: 1,
    selectedValue: 10,
    addLoading: false,
    editLoading: false,
    EditSuccess: false,
    chargerDisplayValues: [],
    chargerCurrentRules: [],
    StateDisplayValues: [],
    StateCurrentRules: [],
    CityDisplayValues: [],
    CityCurrentRules: [],
    currentRules: [],
    displayValues: [],
    FinanceFormValue: {
        cityId: [],
        stateId: [],
        chargerIds: [],
        statusId: [],
        age_op: "",
        age: [],
        more_than_age: "",
        between_age1: "",
        between_age2: "",
        less_than_age: "",
        assigned_to: {},
        TicketStartDate: "",
        TicketEndDate: "",
        ETDStartDate: "",
        ETDEndDate: "",
        ClosureStartDate: "",
        ClosureEndDate: "",
        TaxationStartDate: "",
        TaxationEndDate: "",
        search_key: "",
        sow: "",
        parent_status: "all_tab",
    },
    FinanceTabsLoading: false,
    FinanceTabsData: [],
    FinanceLoading: false,
    FinanceData: [],
    FinanceDetailLoading: false,
    FinanceDealDetailData: [],
    FinanceHistoryLoading: false,
    FinanceHistoryData: [],
    FinanceOthersLoading: false,
    FinanceOthersData: [],
    FinanceButtonLoading: false,
    FinanceButtonData: [],
    deleteTransactionLoading: false,
    deleteTransactionSuccess: false,
    deletePaymentSuccess: false,
    deletePaymentLoading: false,
    addPurchaseOrderSuccess: false,
    addPurchaseOrderLoading: false,
    editTicketDetailsSuccess: false,
    editTicketDetailsLoading: false,
    editTicketStatusSuccess: false,
    editTicketStatusLoading: false,
    deleteTaxInvoiceSuccess: false,
    deleteTaxInvoiceLoading: false,
    editTaxInvoiceSuccess: false,
    editTaxInvoiceLoading: false,
    skipStatusLoading: false,
    skipStatusData: [],
    editSkipStatusSuccess: false,
    editSkipStatusLoading: false,
    ChargerTemplateLoading: false,
    addTransactionSuccess: false,
    addTransactionLoading: false,
    verifyTransactionSuccess: false,
    verifyTransactionLoading: false,
    addTaxInvoiceSuccess:false,
    addTaxInvoiceLoading:false,
    addPaymentTermSuccess: false,
    addPaymentTermLoading: false,
    ChargerTemplateData: [],
    PaymentConditionLoading: false,
    PaymentConditionData: [],
    PaymentTimeLoading: false,
    PaymentTimeData: [],
    FinanceDealTab: 0,
    selectedTab: 0,
    DealDetailsData: {},
    addOpsCommentSuccess: false,
    editOpsCommentSuccess: false,
    deleteOpsCommentSuccess: false,
}

export const getFinanceTabs = createAsyncThunk<Record<string,any>, any, ThunkApiConfig>("getFinanceTabs", async (obj, thunkApi) => {
    try {
        const response = await axiosInstance(`finance/ticket_total`, {
            method: "GET",
            params: {
                state_id: JSON.stringify(obj.data.stateId ?? []),
                status_id: JSON.stringify(obj.data.statusId ?? []),
                city_id: JSON.stringify(obj.data.cityId ?? []),
                assigned_to: obj.data.assigned_to?.id ?? "",
                age: JSON.stringify(obj.data.age ?? []),
                age_op: obj.data.age_op ?? "",
                raised_on_end: obj.data.TicketEndDate ?? "",
                raised_on_start: obj.data.TicketStartDate ?? "",
                due_start: obj.data.ClosureStartDate ?? "",
                due_end: obj.data.ClosureEndDate ?? "",
                charger_type: JSON.stringify(obj.data.chargerIds ?? []),
                search_key: obj.data.search_key ?? "",
                read_filter: obj.data.ReadUnread ?? "all",
                per_page: obj?.selectedValue,
                page: obj?.page,
            },
        })
        return response.data
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.message })
    }
})

export const getFinanceData = createAsyncThunk("getFinanceData", async (obj: any, { thunkApi, dispatch }: any) => {
    try {
        const response = await axiosInstance(`finance/${obj.data.parent_status ?? "all_tab"}`, {
            method: "GET",
            params: {
                state_id: JSON.stringify(obj.data.stateId ?? []),
                status_id: JSON.stringify(obj.data.statusId ?? []),
                city_id: JSON.stringify(obj.data.cityId ?? []),
                assigned_to: obj.data.assigned_to?.id ?? "",
                age: JSON.stringify(obj.data.age ?? []),
                age_op: obj.data.age_op ?? "",
                raised_on_end: obj.data.TicketEndDate ?? "",
                raised_on_start: obj.data.TicketStartDate ?? "",
                due_start: obj.data.ClosureStartDate ?? "",
                due_end: obj.data.ClosureEndDate ?? "",
                charger_type: JSON.stringify(obj.data.chargerIds ?? []),
                search_key: obj.data.search_key ?? "",
                read_filter: obj.data.ReadUnread ?? "all",
                per_page: obj?.selectedValue,
                page: obj?.page,
            },
        })
        return response.data
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.message })
    }
})

export const getTicketDetails = createAsyncThunk("ticketDetail", async (ticket_id: any, thunkApi: any) => {
    try {
        const response = await axiosInstance(`finance/ticket_details`, {
            method: "GET",
            params: {
                ticket_id: ticket_id ?? "",
            },
        })
        return response.data
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.message })
    }
})
export const getTicketHistory = createAsyncThunk("history", async (ticket_id: any, thunkApi: any) => {
    try {
        const response = await axiosInstance(`finance/ticket_history`, {
            method: "GET",
            params: {
                ticket_id: ticket_id ?? "",
            },
        })
        return response.data
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.message })
    }
})
export const getOtherDetails = createAsyncThunk("getOtherDetails", async (ticket_id: any, thunkApi: any) => {
    try {
        const response = await axiosInstance(`finance/other_details`, {
            method: "GET",
            params: {
                ticket_id: ticket_id ?? "",
            },
        })
        return response.data
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.message })
    }
})
export const getSkipStatuses = createAsyncThunk("getStatuses", async (obj: any, thunkApi) => {
    try {
        const response = await axiosInstance(`finance/statuses`, {
            method: "GET",
            params: {
                search_key: obj.search_key ?? "",
            },
        })
        return response.data
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.message })
    }
})
export const getButtonDropdown = createAsyncThunk("getButtonDropdown", async (ticket_id: any, thunkApi) => {
    try {
        const response = await axiosInstance(`finance/button`, {
            method: "GET",
            params: {
                ticket_id: ticket_id ?? "",
            },
        })
        return response.data
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.message })
    }
})

export const getPaymentConditions = createAsyncThunk("getPaymentConditions", async (ticket_id: string, thunkApi) => {
    try {
        const response = await axiosInstance(`finance/conditions`, {
            method: "GET",
            params: {
                ticket_id: ticket_id,
            },
        })
        return response.data
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.message })
    }
})
export const getPaymentTime = createAsyncThunk("getPaymentTime", async (__, thunkApi) => {
    try {
        const response = await axiosInstance(`finance/time_uom`, {
            method: "GET",
        })
        return response.data
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.message })
    }
})

export const addTicketDetails = createAsyncThunk("addFormat", async (thunkAPI: any, { getState, dispatch }) => {
    try {
        const response = await axiosInstance(`${urls.base_url}finance/ticket_details`, {
            method: "POST",
            data: thunkAPI.data,
        })
        response.status === 200 && dispatch(setAlertFunc({ type: "success", msg: response?.data?.message }))
        response.status === 200 && dispatch(getTicketDetails(thunkAPI.data.get("ticket_id")))
        if (response.status === 200 && thunkAPI.toggle !== undefined) {
            thunkAPI.toggle()
        }
        return response.data
    } catch (error: any) {
        dispatch(setAlertFunc({ type: "error", msg: error.message }))
        return thunkAPI.rejectWithValue({ error: error.message })
    }
})

export const addPaymentTerm = createAsyncThunk("addPaymentTerm", async (thunkAPI: any, { getState, dispatch }) => {
    try {
        const response = await axiosInstance(`${urls.base_url}finance/payment_term`, {
            method: "POST",
            data: thunkAPI.data,
        })
        response.status === 201 && dispatch(setAlertFunc({ type: "success", msg: response?.data?.message }))
        response.status === 201 && dispatch(getTicketDetails(thunkAPI.data.get("ticket_id")))
        if (response.status === 201 && thunkAPI.toggle !== undefined) {
            thunkAPI.toggle()
        }
        return response.data
    } catch (error: any) {
        dispatch(setAlertFunc({ type: "error", msg: error.message }))
        return thunkAPI.rejectWithValue({ error: error.message })
    }
})

export const addTransaction = createAsyncThunk("addTransaction", async (thunkAPI: any, { getState, dispatch }: any) => {
    try {
        const response = await axiosInstance(`${urls.base_url}finance/transaction`, {
            method: "POST",
            data: thunkAPI.data,
        })
        response.status === 201 && dispatch(setAlertFunc({ type: "success", msg: response?.data?.message }))
        response.status === 201 && dispatch(getTicketDetails(thunkAPI.data.get("ticket_id")))
        response.status === 201 && dispatch(getButtonDropdown(thunkAPI.data.get("ticket_id")))
        if (response.status === 201 && thunkAPI.toggle !== undefined) {
            thunkAPI.toggle()
        }
        return response.data
    } catch (error: any) {
        dispatch(setAlertFunc({ type: "error", msg: error.message }))
        return thunkAPI.rejectWithValue({ error: error.message })
    }
})
export const addTaxInvoice: any = createAsyncThunk(
    'addTaxInvoice',
    async (thunkAPI: any, { getState, dispatch }) => {

        try {
            const response: any = await axiosInstance(`${urls.base_url}finance/tax_invoice`, {
                method: 'POST',
                data: thunkAPI.data,
            })
            response.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            response.status === 200 && dispatch(getTicketDetails(thunkAPI.data.get('ticket_id')))
            if (response.status === 200 && thunkAPI.toggle !== undefined) {
                thunkAPI.toggle();
            }
            return response.data
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const VerifyTransaction = createAsyncThunk(
    "VerifyTransaction",
    async (thunkApi: any, { getState, dispatch }) => {
        try {
            const response = await axiosInstance(`${urls.base_url}finance/verify_transaction`, {
                method: "POST",
                data: thunkApi.data,
            })
            response.status === 200 && dispatch(setAlertFunc({ type: "success", msg: response?.data?.message }))
            response.status === 200 && dispatch(getTicketDetails(thunkApi.data.get("ticket_id")))
            response.status === 200 && dispatch(getButtonDropdown(thunkApi.data.get("ticket_id")))
            if (response.status === 200 && thunkApi.toggle !== undefined) {
                thunkApi.toggle()
            }
            return response.data
        } catch (error: any) {
            dispatch(setAlertFunc({ type: "error", msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message })
        }
    }
)
export const editTicketDetails = createAsyncThunk(
    "editTicketDetails",
    async (thunkApi: any, { getState, dispatch }) => {
        try {
            const response = await axiosInstance(`${urls.base_url}finance/ticket`, {
                method: "PUT",
                data: thunkApi.data,
            })
            response.status === 200 && dispatch(setAlertFunc({ type: "success", msg: response?.data?.message }))
            response.status === 200 && dispatch(getTicketDetails(thunkApi.data.get("ticket_id")))
            response.status === 200 && dispatch(getOtherDetails(thunkApi.data.get("ticket_id")))
            response.status === 200 && dispatch(getButtonDropdown(thunkApi.data.get("ticket_id")))
            if (response.status === 200 && thunkApi.toggle !== undefined) {
                thunkApi.toggle()
            }
            return response.data
        } catch (error: any) {
            dispatch(setAlertFunc({ type: "error", msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message })
        }
    }
)

export const editSkipStatus = createAsyncThunk("editSkipStatus", async (thunkApi: any, { getState, dispatch }: any) => {
    try {
        const response = await axiosInstance(`${urls.base_url}finance/skip_to_status`, {
            method: "PUT",
            data: thunkApi.data,
        })
        response.status === 200 && dispatch(setAlertFunc({ type: "success", msg: response?.data?.message }))
        response.status === 200 && dispatch(getTicketDetails(thunkApi.data.get("ticket_id")))
        response.status === 200 && dispatch(getButtonDropdown(thunkApi.data.get("ticket_id")))
        return response.data
    } catch (error: any) {
        dispatch(setAlertFunc({ type: "error", msg: error.message }))
        return thunkApi.rejectWithValue({ error: error.message })
    }
})

export const editTicketStatus = createAsyncThunk(
    "editTicketStatus",
    async (thunkApi: any, { getState, dispatch }: any) => {
        try {
            const response = await axiosInstance(`${urls.base_url}finance/ticket`, {
                method: "PUT",
                data: thunkApi.data,
            })
            response.status === 200 && dispatch(setAlertFunc({ type: "success", msg: response?.data?.message }))
            response.status === 200 && dispatch(getTicketDetails(thunkApi.data.get("ticket_id")))
            response.status === 200 && dispatch(getButtonDropdown(thunkApi.data.get("ticket_id")))
            if (response.status === 200 && thunkApi.toggle !== undefined) {
                thunkApi.toggle()
            }
            return response.data
        } catch (error: any) {
            dispatch(setAlertFunc({ type: "error", msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message })
        }
    }
)
export const editTaxInvoice: any = createAsyncThunk(
    'editTaxInvoice', async (thunkApi: any, { getState, dispatch }: any) => {
        try {
            const response: any = await axiosInstance(`${urls.base_url}finance/tax_invoice`, {
                method: 'PUT',
                data: thunkApi.data,
            });
            response.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            response.status === 200 && dispatch(getTicketDetails(thunkApi.data.get("ticket_id")))
            return response.data;
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const deleteTransaction = createAsyncThunk(
    "deleteTransaction",
    async (thunkApi: any, { getState, dispatch }) => {
        const response = await axiosInstance(
            `finance/transaction?ticket_id=${thunkApi?.ticket_id}&user_id=${thunkApi?.user_id}&transaction_id=${thunkApi?.transaction_id}`,
            {
                method: "DELETE",
            }
        )
            .then((data: any) => {
                dispatch(getTicketDetails(thunkApi?.ticket_id))
                data.status === 200 && dispatch(setAlertFunc({ type: "success", msg: data?.data?.message }))
                return data
            })
            .catch((error: any) => {
                dispatch(setAlertFunc({ type: "error", msg: error.message }))
                return thunkApi.rejectWithValue({ error: error.message })
            })
        return response
    }
)

export const deletePaymentTerm = createAsyncThunk(
    "deletePaymentTerm",
    async (thunkApi: any, { getState, dispatch }) => {
        const response = await axiosInstance(
            `finance/payment_term?ticket_id=${thunkApi?.ticket_id}&user_id=${thunkApi?.user_id}&payment_term_id=${thunkApi?.payment_term_id}`,
            {
                method: "DELETE",
            }
        )
            .then((data: any) => {
                dispatch(getTicketDetails(thunkApi?.ticket_id))
                data.status === 200 && dispatch(setAlertFunc({ type: "success", msg: data?.data?.message }))
                return data
            })
            .catch((error: any) => {
                dispatch(setAlertFunc({ type: "error", msg: error.message }))
                return thunkApi.rejectWithValue({ error: error.message })
            })
        return response
    }
)
export const deleteTaxInvoice: any = createAsyncThunk(
    'deleteTaxInvoice', async (thunkApi: any, { getState, dispatch }) => {
        const response: any = await axiosInstance(`finance/tax_invoice?ticket_id=${thunkApi?.ticket_id}&user_id=${thunkApi?.user_id}&id=${thunkApi?.id}`, {
            method: 'DELETE',
        }).then(
            (data: any) => {
                dispatch(getTicketDetails(thunkApi?.ticket_id))
                data.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: data?.data?.message }))
                return data
            }
        ).catch((error: any) => {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        })
        return response
    });
const FinanceSlice = createSlice({
    name: "Finance",
    initialState,
    reducers: {
        setFinanceForm: (state, payload) => {
            state.FinanceFormValue = payload?.payload
        },
        setDisplayValues: (state, payload) => {
            state.displayValues = payload?.payload
        },
        setCurrentRuleValue: (state, payload) => {
            state.currentRules = payload?.payload
        },
        setChargerDisplayValues: (state, payload) => {
            state.chargerDisplayValues = payload?.payload
        },
        setChargerCurrentRuleValue: (state, payload) => {
            state.chargerCurrentRules = payload?.payload
        },
        setCityDisplayValues: (state, payload) => {
            state.CityDisplayValues = payload?.payload
        },
        setCityCurrentRuleValue: (state, payload) => {
            state.CityCurrentRules = payload?.payload
        },
        setStateDisplayValues: (state, payload) => {
            state.StateDisplayValues = payload?.payload
        },
        setStateCurrentRuleValue: (state, payload) => {
            state.StateCurrentRules = payload?.payload
        },
        setPages: (state, payload) => {
            state.page = payload?.payload
        },
        setSelectedValues: (state, payload) => {
            state.selectedValue = payload?.payload
        },
        setDealTab: (state, payload) => {
            state.FinanceDealTab = payload?.payload
        },
        setSelectedTab: (state, payload) => {
            state.selectedTab = payload?.payload
        },
    },
    extraReducers: builder => {
        return (
            builder.addCase(getFinanceTabs.pending, state => {
                state.FinanceTabsLoading = true
            }),
            builder.addCase(getFinanceTabs.fulfilled, (state, { payload }) => {
                state.FinanceTabsLoading = false
                state.FinanceTabsData = payload.data.message
            }),
            builder.addCase(getFinanceTabs.rejected, state => {
                state.FinanceTabsLoading = false
                state.FinanceTabsData = []
                state.DealDetailsData = []
            }),
            builder.addCase(getFinanceData.pending, state => {
                state.FinanceLoading = true
                state.totalPages = 1
                state.FinanceDealDetailData = []
            }),
            builder.addCase(getFinanceData.fulfilled, (state, { payload }) => {
                state.FinanceLoading = false
                state.FinanceData = payload
                state.addOpsCommentSuccess = false
                state.editOpsCommentSuccess = false
                state.deleteOpsCommentSuccess = false
                state.FinanceDealDetailData = []
                state.totalPages = payload?.totalPages
            }),
            builder.addCase(getFinanceData.rejected, state => {
                state.FinanceLoading = false
                state.FinanceData = []
                state.totalPages = 0
            }),
            builder.addCase(getTicketDetails.pending, state => {
                state.FinanceDetailLoading = true
                state.addPurchaseOrderSuccess = false
                state.addTransactionSuccess = false
                state.addPaymentTermSuccess = false
                state.verifyTransactionSuccess = false
                state.addTaxInvoiceSuccess = false
                state.editTaxInvoiceSuccess = false
            }),
            builder.addCase(getTicketDetails.fulfilled, (state, { payload }) => {
                state.FinanceDetailLoading = false
                state.editTicketStatusSuccess = false
                state.editTicketDetailsSuccess = false
                state.addPurchaseOrderSuccess = false
                state.addTransactionSuccess = false
                state.addPaymentTermSuccess = false
                state.verifyTransactionSuccess = false
                state.FinanceDealDetailData = payload
                state.addTaxInvoiceSuccess = false
                state.editTaxInvoiceSuccess = false
            }),
            builder.addCase(getTicketDetails.rejected, state => {
                state.FinanceDetailLoading = false
                state.FinanceDealDetailData = []
            }),
            builder.addCase(getTicketHistory.pending, state => {
                state.FinanceHistoryLoading = true
            }),
            builder.addCase(getTicketHistory.fulfilled, (state, { payload }) => {
                state.FinanceHistoryLoading = false
                state.FinanceHistoryData = payload
            }),
            builder.addCase(getTicketHistory.rejected, state => {
                state.FinanceHistoryLoading = false
                state.FinanceHistoryData = []
            }),
            builder.addCase(getOtherDetails.pending, state => {
                state.FinanceOthersLoading = true
            }),
            builder.addCase(getOtherDetails.fulfilled, (state, { payload }) => {
                state.FinanceOthersLoading = false
                state.FinanceOthersData = payload
            }),
            builder.addCase(getOtherDetails.rejected, state => {
                state.FinanceOthersLoading = false
                state.FinanceOthersData = []
            }),
            builder.addCase(getSkipStatuses.pending, state => {
                state.skipStatusLoading = true
            }),
            builder.addCase(getSkipStatuses.fulfilled, (state, { payload }) => {
                state.skipStatusLoading = false
                state.skipStatusData = payload
            }),
            builder.addCase(getSkipStatuses.rejected, state => {
                state.skipStatusLoading = false
                state.skipStatusData = []
            }),
            builder.addCase(getPaymentTime.pending, state => {
                state.PaymentTimeLoading = true
            }),
            builder.addCase(getPaymentTime.fulfilled, (state, { payload }) => {
                state.PaymentTimeLoading = false
                state.PaymentTimeData = payload
            }),
            builder.addCase(getPaymentTime.rejected, state => {
                state.PaymentTimeLoading = false
                state.PaymentTimeData = []
            }),
            builder.addCase(getPaymentConditions.pending, state => {
                state.PaymentConditionLoading = true
            }),
            builder.addCase(getPaymentConditions.fulfilled, (state, { payload }) => {
                state.PaymentConditionLoading = false
                state.PaymentConditionData = payload
            }),
            builder.addCase(getPaymentConditions.rejected, state => {
                state.PaymentConditionLoading = false
                state.PaymentConditionData = []
            }),
            builder.addCase(getButtonDropdown.pending, state => {
                state.FinanceButtonLoading = true
            }),
            builder.addCase(getButtonDropdown.fulfilled, (state, { payload }) => {
                state.FinanceButtonLoading = false
                state.FinanceButtonData = payload?.data
            }),
            builder.addCase(getButtonDropdown.rejected, state => {
                state.FinanceButtonLoading = false
                state.FinanceButtonData = []
            }),
            builder.addCase(deleteTransaction.pending, state => {
                state.deleteTransactionLoading = true
                state.deleteTransactionSuccess = false
            }),
            builder.addCase(deleteTransaction.fulfilled, (state, { payload }) => {
                state.deleteTransactionLoading = false
                state.deleteTransactionSuccess = true
            }),
            builder.addCase(deleteTransaction.rejected, state => {
                state.deleteTransactionLoading = false
                state.deleteTransactionSuccess = false
            }),
            builder.addCase(deletePaymentTerm.pending, state => {
                state.deletePaymentLoading = true
                state.deletePaymentSuccess = false
            }),
            builder.addCase(deletePaymentTerm.fulfilled, (state, { payload }) => {
                state.deletePaymentLoading = false
                state.deletePaymentSuccess = true
            }),
            builder.addCase(deletePaymentTerm.rejected, state => {
                state.deletePaymentLoading = false
                state.deletePaymentSuccess = false
            }),
            builder.addCase(addTicketDetails.pending, state => {
                state.addPurchaseOrderLoading = true
                state.addPurchaseOrderSuccess = false
            }),
            builder.addCase(addTicketDetails.fulfilled, state => {
                state.addPurchaseOrderLoading = false
                state.addPurchaseOrderSuccess = true
            }),
            builder.addCase(addTicketDetails.rejected, state => {
                state.addPurchaseOrderLoading = false
                state.addPurchaseOrderSuccess = false
            }),
            builder.addCase(addTransaction.pending, state => {
                state.addTransactionLoading = true
                state.addTransactionSuccess = false
            }),
            builder.addCase(addTransaction.fulfilled, state => {
                state.addTransactionLoading = false
                state.addTransactionSuccess = true
            }),
            builder.addCase(addTransaction.rejected, state => {
                state.addTransactionLoading = false
                state.addTransactionSuccess = false
            }),
            builder.addCase(addTaxInvoice.pending, (state: any) => {
                state.addTaxInvoiceLoading = true
                state.addTaxInvoiceSuccess = false
            }),
            builder.addCase(addTaxInvoice.fulfilled, (state: any) => {
                state.addTaxInvoiceLoading = false
                state.addTaxInvoiceSuccess = true
            }),
            builder.addCase(addTaxInvoice.rejected, (state: any) => {
                state.addTaxInvoiceLoading = false
                state.addTaxInvoiceSuccess = false
            }),
            builder.addCase(editTaxInvoice.pending, (state: any) => {
                state.editTaxInvoiceLoading = true
                state.editTaxInvoiceSuccess = false
            }),
            builder.addCase(editTaxInvoice.fulfilled, (state: any) => {
                state.editTaxInvoiceLoading = false
                state.editTaxInvoiceSuccess = true
            }),
            builder.addCase(editTaxInvoice.rejected, (state: any) => {
                state.editTaxInvoiceSuccess = false
            }),
            builder.addCase(VerifyTransaction.pending, state => {
                state.verifyTransactionLoading = true
                state.verifyTransactionSuccess = false
            }),
            builder.addCase(VerifyTransaction.fulfilled, state => {
                state.verifyTransactionLoading = false
                state.verifyTransactionSuccess = true
            }),
            builder.addCase(VerifyTransaction.rejected, state => {
                state.verifyTransactionLoading = false
                state.verifyTransactionSuccess = false
            }),
            builder.addCase(addPaymentTerm.pending, state => {
                state.addPaymentTermLoading = true
                state.addPaymentTermSuccess = false
            }),
            builder.addCase(addPaymentTerm.fulfilled, state => {
                state.addPaymentTermLoading = false
                state.addPaymentTermSuccess = true
            }),
            builder.addCase(addPaymentTerm.rejected, state => {
                state.addPaymentTermLoading = false
                state.addPaymentTermSuccess = false
            }),
            builder.addCase(editTicketDetails.pending, state => {
                state.editTicketDetailsLoading = true
                state.editTicketDetailsSuccess = false
            }),
            builder.addCase(editTicketDetails.fulfilled, state => {
                state.editTicketDetailsLoading = false
                state.editTicketDetailsSuccess = true
            }),
            builder.addCase(editTicketDetails.rejected, state => {
                state.editTicketDetailsSuccess = false
            }),
            builder.addCase(editSkipStatus.pending, state => {
                state.editSkipStatusLoading = true
                state.editSkipStatusSuccess = false
            }),
            builder.addCase(editSkipStatus.fulfilled, state => {
                state.editSkipStatusLoading = false
                state.editSkipStatusSuccess = true
            }),
            builder.addCase(editSkipStatus.rejected, state => {
                state.editSkipStatusSuccess = false
            }),
            builder.addCase(editTicketStatus.pending, state => {
                state.editTicketStatusLoading = true
                state.editTicketStatusSuccess = false
            }),
            builder.addCase(editTicketStatus.fulfilled, state => {
                state.editTicketStatusLoading = false
                state.editTicketStatusSuccess = true
            }),
            builder.addCase(editTicketStatus.rejected, state => {
                state.editTicketStatusSuccess = false
            })
        )
    },
})

export const {
    setDisplayValues,
    setChargerCurrentRuleValue,
    setChargerDisplayValues,
    setCurrentRuleValue,
    setFinanceForm,
    setCityCurrentRuleValue,
    setCityDisplayValues,
    setStateCurrentRuleValue,
    setStateDisplayValues,
    setPages,
    setSelectedValues,
    setSelectedTab,
    setDealTab,
} = FinanceSlice.actions
export const getAllUser = (state: any) => state.users.loading
export const addUserSuccess = (state: any) => state.users.addSuccess

export default FinanceSlice.reducer
