import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { setAlertFunc } from '../../../../components/AlertToaster/AlertSlice';
import { axiosInstance } from '../../../Auth/components/axiosInterceptors'

const initialState = {
    reasons: [],
    Loading: false,
    editSuccess: false,
    addSuccess: false,
    editLoading: false,
    addLoading: false

}

export const getReasons = createAsyncThunk(
    'getReasons', async (thunkAPI:any) => {
        try {
            const params = {
                "submodule_id": thunkAPI?.submodule_id || "",
                "module_id": thunkAPI?.module_id,
            }
            const response: any = await axiosInstance(`settings/reasons`, {
                method: 'GET',
                params
            })
            return await response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    })

export const addReasons: any = createAsyncThunk('addReasons',
    async (thunkAPI: any, { getState, dispatch }: any) => {
        try {
            const res = await axiosInstance('settings/reasons', {
                method: 'POST',
                data: JSON.stringify({
                    reason: thunkAPI?.reason,
                    reason_type: thunkAPI?.reason_type,
                    module_id: thunkAPI?.module_id,
                    submodule_id: thunkAPI?.submodule_id??"",
                })
            })
            dispatch(setAlertFunc({ type: 'success', msg: res?.data?.message }))
            return res.data
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
)
export const editReasons: any = createAsyncThunk('editReasons',
    async (thunkAPI: any, { getState, dispatch }: any) => {
        try {
            const res = await axiosInstance('settings/reasons', {
                method: 'PUT',
                data: JSON.stringify(thunkAPI)
            })
            dispatch(setAlertFunc({ type: 'success', msg: res?.data?.message }))
            return res.data
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkAPI.rejectWithValue({ error: error.message });
        }

    }
)

const ReasonSlice = createSlice({
    name: 'reasons',
    initialState,
    reducers: {},
    extraReducers: (builder: any) => {
        return builder.addCase(getReasons.pending, (state: any) => {
            state.Loading = true
            state.reasons = []
            state.addSuccess = false
            state.editSuccess = false
        }),
            builder.addCase(getReasons.fulfilled, (state: any, { payload }: any) => {
                state.Loading = false
                state.reasons = payload
                // state.addSuccess = false
                // state.editSuccess = false
            }),
            builder.addCase(getReasons.rejected, (state: any) => {
                state.Loading = false
                state.reasons = []
            }),
            builder.addCase(addReasons.pending, (state: any) => {
                state.addLoading = true
                state.addSuccess = false
            }),
            builder.addCase(addReasons.fulfilled, (state: any) => {
                state.addLoading = false
                state.addSuccess = true
            }),
            builder.addCase(addReasons.rejected, (state: any) => {
                state.addLoading = false
                state.addSuccess = false
            }),
            builder.addCase(editReasons.pending, (state: any) => {
                state.editLoading = true
                state.editSuccess = false
            }),
            builder.addCase(editReasons.fulfilled, (state: any) => {
                state.editLoading = false
                state.editSuccess = true
            }),
            builder.addCase(editReasons.rejected, (state: any) => {
                state.editLoading = false
                state.editSuccess = false
            })
    }
})

export default ReasonSlice.reducer