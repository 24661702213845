import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../../.../../../Auth/components/axiosInterceptors';
import { setAlertFunc } from '../../../../components/AlertToaster/AlertSlice';


const initialState = {
    category_id: "",
    report_id: "",
    loading: false,
    addSuccess: false,
    rules: [],
    editLoading: false,
    EditSuccess: false
}


export const getRules: any = createAsyncThunk(
    'rules', async (thunkApi: any) => {
        try {
            const response: any = await axiosInstance(`settings/staterule`, {
                method: 'GET',
                data: JSON.stringify(thunkApi)
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });


export const editUploadRules: any = createAsyncThunk(
    'editUploadRules', async (thunkApi: any, { getState, dispatch }) => {

        try {
            const response: any = await axiosInstance(`settings/staterule`, {
                method: 'PUT',
                data: JSON.stringify(thunkApi)
            })
            dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            dispatch(getRules())
            return response.data
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        }
    })

const RulesSlice = createSlice({
    name: 'rules',
    initialState,
    reducers: {},
    extraReducers: (builder: any) => {
        return builder.addCase(getRules.pending, (state: any) => {
            state.loading = true
            state.rules = []
            state.EditSuccess = false
        }),
            builder.addCase(getRules.fulfilled, (state: any, { payload }: any) => {
                state.loading = false
                state.rules = payload
                state.EditSuccess = false
            }),
            builder.addCase(getRules.rejected, (state: any) => {
                state.loading = false
                state.rules = []
            }),
            builder.addCase(editUploadRules.pending, (state: any) => {
                state.editLoading = true
                state.EditSuccess = false
            }),
            builder.addCase(editUploadRules.fulfilled, (state: any) => {
                state.editLoading = false
                state.EditSuccess = true
            }),
            builder.addCase(editUploadRules.rejected, (state: any) => {
                state.editLoading = false
                state.EditSuccess = false
            })

    },
})

export const getAllRules = (state: any) => state.rules
export const addRulesSuccess = (state: any) => state.rules.addSuccess
export const editRulesSuccess = (state: any) => state.rules.addSuccess

export default RulesSlice.reducer;

