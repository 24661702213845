import axios from "axios"
import { axiosInstance } from "../pages/Auth/components/axiosInterceptors"
import pdf_svg from '../assets/images/pdf_svg.svg';
import folder_svg from '../assets/images/folder_icon.png';
import excel_svg from '../assets/images/excel_svg.svg';
import no_image from '../assets/images/no_image_svg.svg';
import image_uploaded from '../assets/images/image_uploaded_svg.svg';

export const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
}

export const isValidNonMandatoryEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return email === "" || emailRegex.test(email)
}

export const validateLong = (value: string | number) => {
    return /^\d+\.\d{6,10}?$/.test(value as string)
}

export const isValidPhoneNumber = (phoneNumber: string | number) => {
    let trimmedValue = typeof(phoneNumber) === 'number' ? phoneNumber?.toString()?.trim() :  phoneNumber?.trim();
    return trimmedValue?.length === 10
}

export const isValidPincode = (pincode: string) => {
    return pincode?.trim()?.length === 6
}

export const isValidGST = (gstNo: string) => {
    return gstNo?.trim()?.length === 15
}

export const isValidUTRNumber = (utr: string) => {
    return utr?.trim()?.length <= 20
}

export const isValidPercentage = (value: string) => {
    let numericValue = parseFloat(value)
    return numericValue >= 0 && numericValue <= 100
}

export const renderImageType = (docType: string) => {
    switch (docType) {
      case 'excel':
        return excel_svg;
      case 'doc':
        return pdf_svg;
      case 'folder':
        return folder_svg;
      case 'image':
        return image_uploaded;
      case null:
      case 'None':
        return no_image;
      default:
        return no_image;
    }
  }
interface PresignedUrl {
    presigned_url: string
    file_url: string[] | string
    file_type: string
    report_id?: number
    doc_id?: number
}

interface UrlsResponse {
    urls: { urls: PresignedUrl[]; file_type: string }[]
}

export const uploadFilesToS3 = async (fileObj: Record<string, File[]>, ticket_id: string = "", directUpload = false) => {
    try {
        const filePayload = Object.keys(fileObj).map(fileType => ({
            file_type: fileType,
            file_name: fileObj[fileType]?.map(file => file.name),
        }))
        
        const payload = {
            ticket_id: ticket_id,
            files: filePayload,
        }

        const response = await axiosInstance.post<UrlsResponse>("upload_documents", payload)
        const urls = response.data?.urls

        if (urls && urls.length > 0) {
            const promises: Promise<any>[] = []
            for (const fileType of Object.keys(fileObj)) {
                const files = fileObj[fileType]

                const urlSet = urls.find(u => u.file_type === fileType)
                if (urlSet) {
                    for (let i = 0; i <= files?.length - 1; i++) {
                        const uploadPromise = axios.put(urlSet.urls[i].presigned_url, files[i], {
                            headers: {
                                "Content-Type": files[i].type,
                            },
                        })
                        promises.push(uploadPromise)
                    }
                } else {
                    console.error(`No Url found for key: ${fileType}`)
                }
            }

            try {
                await Promise.all(promises)
                let finalPayload = {
                    ticket_id: ticket_id,
                    file_infos: urls
                        ?.map(u => u.urls)
                        ?.flat()
                        ?.map(i => ({
                            file_url: i.file_url[0],
                            report_id: i.report_id,
                            doc_id: i.doc_id,
                        })),
                }
                directUpload && await submitFinalUploads(finalPayload)
                return urls
            } catch (error) {
                console.error("Error uploading files:", error)
                throw error
            }
        } else {
            console.error("No URLs returned from the server")
            throw new Error("No URLs returned from the server")
        }
    } catch (error) {
        console.error("Error uploading files:", error)
        throw error
    }
}

export const deleteFiles = async (ids: number | number[]) => {
    try {
        let payload = {
            ids: typeof ids === "number" ? [ids] : ids,
        }
        await axiosInstance.delete("delete_documents", {
            data: payload,
        })
    } catch (error) {
        console.error("File Deletion Failed!", error)
        throw error
    }
}

export type FileInfo = Omit<PresignedUrl, "presigned_url" | "file_type">

export const submitFinalUploads = async (payload: { ticket_id: string; file_infos: FileInfo[] }) => {
    try {
        await axiosInstance.put("submit_final_uploads", payload)
    } catch (error) {
        console.error("Error uploading files: ", error)
        throw error
    }
}

export const getTagType = (ticket_type: string) => {
    return ticket_type
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join('');
  };